import React from 'react'
import styled from 'styled-components/macro'

const GuideNav = ({
  version,
  goToSection,
  currentSection,
}) => {

  return <GuideNavWrapper data-test-id="guide-nav">
    {version.sections && version.sections.map( (section, index) => {
      return <div key={index}>
        <GuideNavItem
          onClick={() => goToSection(index)}
          active={currentSection === index}
        >
          {section.title}
        </GuideNavItem>
      </div>
    })}
    <GuideNavItem
      onClick={() => goToSection('appendix')}
      active={currentSection === 'appendix'}
    >
      Appendix
    </GuideNavItem>
  </GuideNavWrapper>
}

const GuideNavWrapper = styled.div`
  position: sticky;
  top: 4rem;

  max-width: 10rem;
  font-size: var(--xs);

  opacity: .8;
  /* transition: opacity .2s ease; */

  :hover {
    opacity: 1;
  }
`
const GuideNavItem = styled.div`
  cursor: pointer;
  padding-bottom: 1rem;
  color: ${p => p.active ? 'var(--text-primary)' : 'var(--text-secondary)'};
  font-weight: ${p => p.active ? '500' : '400'};

  &:hover {
    color: var(--text-primary);
  }
`

export default GuideNav
