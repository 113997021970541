import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

const ButtonIconWrapper = styled.div`
  display: grid;
  grid-column-gap: ${p => p.padded ? '1rem' : '.5rem'};
  grid-template-columns: max-content max-content;
  align-items: center;
  justify-content: center;

  margin-left:  ${ p => p.right ? '0'      : '-.25rem'};
  margin-right: ${ p => p.right ? '-.25rem' : '0'     };

  i {
    width: 20px;
    height: 20px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const ButtonMixin = css`
  vertical-align: top;
  border: none;
  font-family: var(--font-family-sans);
  font-weight: 500;
  letter-spacing: 0.015rem;
  border-radius: ${ p => p.fullRound ? '1000px' : '4px'};
  cursor: ${p => p.working ? 'auto' : p.disabled ? 'auto' : 'pointer'};
  opacity: ${p => p.working ? .5 : .95};
  max-width: 25rem;
  text-align: center;
  user-select: none;
  margin-left: ${p => p.padLeft && '1rem'};  /* needs to be above other margin def */
  margin-right: ${p => p.padRight && '1rem'};  /* needs to be above other margin def */
  font-size: ${p => p.small ? 'var(--s)' : 'var(--m)'};
  position: relative;
  line-height: 1.2;
  
  /* block */
  display: ${p => p.block ? 'block' : 'inline-block'};
  margin: ${p => p.block ? '0 auto' : p.vertSpace ? '0 auto .5rem' : 'auto' };

  width: ${p => p.wide && '100%'};
  
  /* color */
  background: ${p => p.secondary ? 'white' : 
                     p.destructive ? 'var(--destructive)' : 
                     'var(--brand-primary)'};
  color: ${p =>      p.tertiary && p.destructive ? 'var(--destructive)' : 
                     p.secondary ? 'var(--text-primary)' : 
                     'white'};
  box-shadow: ${p => p.secondary ? '0 1px 2px var(--shadow)' : 
                     p.destructive ? 'none' : 
                     '0 20px 30px -25px var(--brand-primary)'};
  border: ${p =>     p.secondary ? '1px solid var(--hairline-dark)' : 
                     '1px solid transparent'};

  /* size */
  padding: ${p => p.big ? '1rem 2rem' : p.small ? '.5rem 1rem' : '.66rem 1.33rem'};
  padding-right: ${p => (p.big && p.working) ? '3.5rem' :
                        (p.small && p.working) ? '2.75rem' :
                        p.working ? '3rem' :
                        undefined };


  transition: opacity .1s ease;
  &:hover {
    opacity: ${p => p.working ? .5 : 1};
    border: ${p => p.secondary ? '1px solid var(--hairline-darker)' : 
                  '1px solid transparent'};
  }

  outline: none;
  &:focus {
    box-shadow: inset 0 0 0 2px var(--blue-80);
  }

  &:disabled {
    opacity: .3;
    cursor: auto;
  }

  &:before {
    display: ${p => p.working ? 'block' : 'none'};
    content: '';
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 14px;
    height: 14px;
    margin-top: -8px;
    margin-left: -8px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: var(--white);
    animation: spinner .6s linear infinite;
  }

  ${ButtonIconWrapper} {
    grid-template-columns: ${p => p.wide && 'max-content auto'};
  }

  @media(max-width: 599px) {
    display: block;
    width: 100%;
    margin: .5rem auto 0;
  }
`

const Button = styled.button`
  ${ButtonMixin}
`
const ButtonLink = styled.a`
  ${ButtonMixin}
`
const ButtonAppLink = styled(Link)`
  ${ButtonMixin}
`

const ButtonSubText = styled.div`
  font-size: var(--xs);
  margin: .5rem 0;
`

const AppLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: ${p => p.destructive ? 'var(--destructive)' : 'var(--link)'};

  outline: none;
  &:focus {
    border-bottom: 2px solid var(--blue-80);
  }

  &:hover {
    text-decoration: underline;
  }
`

export {
  Button,
  ButtonLink,
  ButtonAppLink,
  ButtonIconWrapper,
  ButtonSubText,
  AppLink,
}
