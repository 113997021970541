import React from 'react'
import styled from 'styled-components/macro'
import { useMediaQuery } from 'react-responsive'

import { Select, SelectWrapper } from 'styles/forms'
import Dropdown from 'views/_components/Dropdown'
import {
  DropdownMenuAction,
} from 'styles/dropdown'


const arrayMove = (array, fromIndex, toIndex) => {
  var element = array[fromIndex]
  array.splice(fromIndex, 1)
  array.splice(toIndex, 0, element)
}

// The "setTimeouts" in this component are horrible and I'm deeply, deeply ashamed
// Without em it don't work rite tho
// I think the real answer is storing sections in a map with keys and an order value
// Requires a migration tho so... TODO later i guess SORRY

const GuideEditNav = ({
  localDraft,
  currentSection,
  setCurrentSection,
  updateDraft,
}) => {

  const isMobile = useMediaQuery({ query: '(max-width: 599px)' })

  const sections = !localDraft ? [] : !localDraft.sections ? [] : localDraft.sections

  const addSection = () => {
    const response = window.prompt(`What's your new section called?`)
    if (!response) {
      return
    }

    updateDraft({
      ...localDraft,
      sections: [
        ...sections,
        {
          title: response,
          content: '',
        }
      ]
    })

    setTimeout( () => setCurrentSection(localDraft.sections.length), 100)
  }

  const renameSection = index => {
    const name = window.prompt(`What would you like to call this section?`)
    if (!name) {
      return
    }

    sections[index].title = name

    updateDraft({
      ...localDraft,
      sections,
    })
  }

  const moveUp = index => {
    arrayMove(sections, index, index - 1)
    
    updateDraft({
      ...localDraft,
      sections,
    })

    setTimeout( () => setCurrentSection(index - 1), 100)
  }

  const moveDown = index => {
    arrayMove(sections, index, index + 1)
    
    updateDraft({
      ...localDraft,
      sections,
    })    
    
    setTimeout( () => setCurrentSection(index + 1), 100)
  }

  const removeSection = index => {
    const confirm = window.confirm(`Are you sure? Your section's content will be deleted and can't undo this.`)
    if (!confirm) {
      return
    }

    sections.splice(index, 1)
    
    updateDraft({
      ...localDraft,
      sections,
    })

    setTimeout( () => {
      if (currentSection === index && currentSection !== 0) {
        setCurrentSection(currentSection - 1)
      }  
    }, 100)

  }

  return <GuideEditNavWrapper data-test-id="new-guide-nav">

    { !isMobile ?
      <>
        <GuideEditNavItem
          onClick={() => setCurrentSection('basic')}
          active={currentSection === 'basic'}
          >
          Basic info
        </GuideEditNavItem>

        <br />

        {
          sections.map( (section, index) => {
            return <div key={index}>
              <GuideEditNavItem
                onClick={() => setCurrentSection(index)}
                active={currentSection === index}
              >

                <div>
                  {section.title}
                </div>

                <Dropdown
                  left
                  lowered
                  toggle={
                    <MenuIcon>
                      <i className="material-icons-round">more_horiz</i>
                    </MenuIcon>
                  }
                >
                  { ({close}) => {
                    
                    return <div>

                      <DropdownMenuAction
                        disabled={index === 0}
                        onClick={() => {
                          close && close()
                          if (index === 0) {
                            return
                          }
                          moveUp(index)
                        }}
                        >
                        <i className="material-icons-round">arrow_upward</i>
                        Move up
                      </DropdownMenuAction>

                      <DropdownMenuAction
                        disabled={index >= sections.length - 1}
                        onClick={() => {
                          close && close()
                          if (index >= sections.length - 1) {
                            return
                          }
                          moveDown(index)
                        }}
                      >
                        <i className="material-icons-round">arrow_downward</i>
                        Move down
                      </DropdownMenuAction>

                      <DropdownMenuAction
                        onClick={() => {
                          close && close()
                          renameSection(index)
                        }}
                      >
                        <i className="material-icons-round">edit</i>
                        Rename
                      </DropdownMenuAction>

                      <hr />
                      
                      <DropdownMenuAction
                        destructive
                        onClick={() => {
                          close && close()
                          removeSection(index)
                        }}
                      >
                        <i className="material-icons-round">delete_outline</i>
                        Delete
                      </DropdownMenuAction>

                    </div>
                  }}
                </Dropdown>

              </GuideEditNavItem>
            </div>
          })
        }

        <AddSectionLink onClick={addSection}>
          + Add section
        </AddSectionLink>
      </>
      :
      <SelectWrapper>
        <Select onChange={ event => {
          const { value } = event.target
          if (value === 'add') {
            addSection()
          } else {
            setCurrentSection(value)
          }
        }}>
          <option value="basic">Basic info</option>

          { sections.map( (section, index) => {
            return <option key={index} value={index}>{section.title}</option>
          })}

          <option value="add">+ Add section</option>
        </Select>
      </SelectWrapper>
    }

  </GuideEditNavWrapper>
}

const GuideEditNavWrapper = styled.div`
  position: sticky;
  top: 1rem;
`
const GuideEditNavItem = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  grid-column-gap: 0.5rem;
  align-items: center;

  cursor: pointer;
  padding: .75rem 1rem;
  line-height: 1.1;
  font-weight: 500;
  font-size: var(--s);
  color: var(--text-primary);
  margin: 0 0 -1px;
  border: 1px solid var(--hairline-dark);
  user-select: none;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    width: 3px;
    background: ${p => p.active ? 'var(--brand-primary)' : 'transparent'};
    z-index: 10;
  }

  &:hover {
    background: var(--hover-light);
  }
`
const MenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  width: 2rem;
  height: 2rem;
  margin: -.5rem;
  border-radius: 100px;

  transition: all .2s ease;
  opacity: .4;
  &:hover {
    background: var(--hover);
    opacity: .8;
  }
  
  i {
    font-size: 16px;
  }
`
const AddSectionLink = styled.div`
  text-decoration: underline;
  cursor: pointer;
  padding: .75rem;
  font-size: var(--xs);
  color: var(--text-primary);
`

export default GuideEditNav
