import Firebase from 'firebase/app'
import { prepareDocForCreate } from './helpers/firestoreHelpers'

const saveGuide = guide => {
  const save = prepareDocForCreate({
    guideId: guide.id,
  })
  return Firebase.firestore()
    .collection('guideSaves')
    .add(save)
}

export default saveGuide
