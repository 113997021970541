import React from 'react'
import styled from 'styled-components/macro'

import LogError from 'actions/LogError'
import ErrorLayout from 'views/_layouts/ErrorLayout'
import { AppLink } from 'styles/buttons'

const ErrorPage = ({error}) => {

  LogError('from error page', error, true)

  if (error?.message?.includes('Missing or insufficient permissions')) {
    return <ErrorLayout title="No access">
      <h1>Sorry, you don't have access to this page</h1>
      <br />
      <p>The owner of your team's Superclass account may have removed your access.</p>
      <p>
        If you think this is an error,
        {' '}
        <AppLink to="" as="a" href="https://superclass.co/contact" target="_blank" rel="noreferrer">contact our support team</AppLink>
        {' '}
        and we'll get it straighted out right away.
      </p>

      <br />
      <AppLink to={'/'}>Go to the Superclass homepage</AppLink>
      
    </ErrorLayout>
  }
  
  return <ErrorLayout title="Whoops!">
    <h1>Whoops</h1>
    <br />
    <p>Sorry, something went wrong. This could be a Superclass error, or maybe you don't have access to the guide you're trying to reach.</p>
    <ErrorMessage>{error && error.message}</ErrorMessage>

    <br />
    <AppLink to={'/'}>Go to the Superclass homepage</AppLink>
    
  </ErrorLayout>
}

const ErrorMessage = styled.div`
  font-family: monospace;
  font-size: var(--s);
`

export default ErrorPage