import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import ResourceLinkComponent from './ResourceLinkComponent'
import {
  RESOURCE_LINK_NAME,
  RESOURCE_LINK_HTML_TAG,
} from './EditorConstants'

// https://www.tiptap.dev/guide/node-views/react

const ResourceLinkExtension = Node.create({
  name: RESOURCE_LINK_NAME,
  group: 'block',
  draggable: true,
  atom: true, // ????

  addAttributes() {
    return {
      url: {
        default: null,
      },
      resourceId: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: RESOURCE_LINK_HTML_TAG,
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [RESOURCE_LINK_HTML_TAG, mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(ResourceLinkComponent)
  },
})

export default ResourceLinkExtension
