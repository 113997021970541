import React from 'react'
import { FirestoreDocument, FirestoreCollection } from 'react-firestore'

import { AppStateContext } from 'views/AppState'
import GuideEdit from 'views/guide_edit/GuideEdit'
import EnsureLoggedIn from 'views/EnsureLoggedIn'
import ErrorPage from 'views/_components/ErrorPage'
import { LoadingPage } from 'styles/loading'

const GuideEditContainer = ({ match }) => {

  const { guideString } = match.params
  const guideId = guideString.split('-')[0]

  return <EnsureLoggedIn>

    <AppStateContext.Consumer>
      { ({ auth }) => {

        return <FirestoreDocument path={`/users/${auth.uid}`}>
          {({ isLoading, error, data, snapshot }) => {
            if (isLoading) {
              return <LoadingPage>Loading</LoadingPage>
            }
            if (error) {
              return <ErrorPage error={error} />
            }

            let user = {
              id: auth.uid,
              ref: snapshot.ref,
              ...data,
            }

            return <FirestoreDocument path={`guides/${guideId}`}>
              {({ isLoading, error, data, snapshot }) => {
                if (isLoading) {
                  return <LoadingPage>Loading</LoadingPage>
                }
                if (error) {
                  return <ErrorPage error={error} />
                }

                const guide = data
                guide.ref = snapshot.ref

                if (!snapshot.exists) {
                  return <ErrorPage error={new Error(`This guide does not exist or has been deleted`)} />
                }

                return <FirestoreCollection path={`guides/${guideId}/drafts`}>
                  {({ isLoading, error, snapshot }) => {
                    if (isLoading) {
                      return <LoadingPage>Loading</LoadingPage>
                    }
                    if (error) {
                      return <ErrorPage error={error} />
                    }
            
                    const drafts = snapshot.docs.map( snap => ({
                      ref: snap.ref,
                      id: snap.id,
                      ...snap.data(),
                    }))
                    
                    const draft = drafts[0]

                    return <GuideEdit
                      guide={guide}
                      draft={draft}
                      user={user}
                    />

                  }}
                </FirestoreCollection>
              }}

            </FirestoreDocument>

          }}
        </FirestoreDocument>

      }}
    </AppStateContext.Consumer>

  </EnsureLoggedIn>
}

export default GuideEditContainer
