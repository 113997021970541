import Firebase from 'firebase/app'

const resetPassword = ({email}) => {
  return Firebase.auth()
    .sendPasswordResetEmail(email)
    .then( () => {
      console.log(`Requested password reset for ${email}`)
    })
}

export default resetPassword
