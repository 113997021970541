import * as Sentry from '@sentry/react'
import LogRocket from 'logrocket'

const LogError = (action, error, dontShowAlert) => {
  console.error(`Error during "${action || 'undefined action'}": ${error ? error.message : 'no error object passed to logger'}`, error)

  // error tracking
  // https://docs.sentry.io/clients/javascript/usage/
  Sentry.captureException(error, { extra: { action } })
  // https://docs.logrocket.com/docs/error-reporting
  LogRocket.captureException(error, { extra: { action } })

  // alert user
  if (!dontShowAlert) {
    alert(`Sorry, we couldn't ${action} right now. Please try again in a minute!`)
  }
}

export default LogError
