import Firebase from 'firebase/app'

const unsaveGuide = guideSave => {
  return Firebase.firestore()
    .collection('guideSaves')
    .doc(guideSave.id)
    .delete()
}

export default unsaveGuide
