import styled from 'styled-components/macro'

const AdminHeader = styled.div`
  display: grid;
  grid-template-columns: 80px auto;
  grid-column-gap: 2rem;
  margin: 0 0 1rem;
  padding: 0 0 1rem;
  border-bottom: 2px solid var(--hairline);

  div:first-child {
    text-align: center;
    font-size: 40px;
  }

  h1 {
    margin: 0 0 .5rem;
  }
`
const TwoCol = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-column-gap: 3rem;
  margin: 0 0 1rem;
  padding: 0 0 1rem;
  border-bottom: 2px solid var(--hairline);
`
const Empty = styled.div`
  color: var(--text-secondary);
  font-size: var(--s);
  margin: 0 0 2rem;
  background: var(--gray-96);
  padding: .5rem;
`
const Table = styled.table`
  width: 100%;
  text-align: left;
  color: var(--text-primary);
  font-size: var(--s);
  margin: 0 0 2rem;
  border: none;
  border-collapse: collapse;
`
const TBody = styled.tbody`
`
const Cell = styled.td`
  vertical-align: top;
  border-top: 1px solid var(--hairline);
  font-weight: ${p => p.th && 500};
  padding-right: 1rem;
`
const Row = styled.tr`
  &:first-child ${Cell} {
    border: none !important;
  }
`
const A = styled.a`
  text-decoration-color: var(--blue-90);
  text-decoration: ${p => !p.disabled && 'underline'};
  font-style: ${p => p.disabled && 'italic'};
  opacity: ${p => p.disabled && .5};
  cursor: ${p => p.disabled ? 'auto' : 'pointer'};
  color: ${p => p.disabled ? 'var(--text-tertiary)' : 'var(--brand-primary)'};
`
const Copy = styled.span`
  display: inline-block;
  padding: 2px 4px;
  margin: -2px -4px;
  border-radius: 3px;
  cursor: pointer;

  max-width: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: monospace;

  background: var(--hover);
  opacity: .85;

  &:hover {
    opacity: 1;
  }
  &:active {
    transform: translateY(1px);
  }
`

export {
  AdminHeader,
  TwoCol,
  Empty,
  Table,
  TBody,
  Row,
  Cell,
  A,
  Copy,
}
