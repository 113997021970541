import React from 'react'
import { FirestoreCollection } from 'react-firestore'
import styled from 'styled-components/macro'

import { AppStateContext } from 'views/AppState'
// import BubbleHeadItem from 'views/_components/BubbleHeadItem'

const GuideSaves = ({
  guide,
}) => {

  return <AppStateContext.Consumer>
    { ({ auth, authLoading}) => {

      if (!auth || authLoading) {
        return null
      }

      return <FirestoreCollection
        path={'guideSaves'}
        filter={[
          ['guideId', '==', guide.id],
        ]}
      >
          { ({error, isLoading, data}) => {

            if (error || isLoading) {
              return null
            }

            const saves = data
            // const saveBubbleHeads = data.slice(0, 3)

            return <GuideSavesWrapper>
              {/* <div>
                {saveBubbleHeads.map( save => {
                  return <BubbleHeadItem key={save.createdBy} userId={save.createdBy} size={24} />
                })}
              </div> */}
              <div>
                {
                  saves.length === 0 ? ''
                  :
                  saves.length === 1 ? '1 save'
                  :
                  `${saves.length} saves`
                }
              </div>
            </GuideSavesWrapper>
          }}
        </FirestoreCollection>

    }}
  </AppStateContext.Consumer>
}

const GuideSavesWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  grid-column-gap: .5rem;

  font-size: var(--xs);
`

export default GuideSaves
