const GUIDE_COLORS = [
  {
    id:         "magenta",
    name:       "Magenta",
    color:      "hsl(350, 99%, 46%)",
    background: "hsl(330, 80%, 97%)",
  },
  {
    id:         "red",
    name:       "Red",
    color:      "hsl(10,  99%, 46%)",
    background: "hsl(350, 80%, 97%)",
  },
  {
    id:         "orange",
    name:       "Orange",
    color:      "hsl(13deg  80% 48%)",
    background: "hsl(50deg 100% 95%)",
  },
  {
    id:         "yellow",
    name:       "Yellow",
    color:      "hsl(35, 100%, 45%)",
    background: "hsl(60,  90%, 97%)",
  },
  {
    id:         "green",
    name:       "Green",
    color:      "hsl(320deg 100% 30%)",
    background: "hsl( 90deg  90% 96%)",
  },
  {
    id:         "blue",
    name:       "Blue",
    color: "hsl(260deg 90% 50%)",
    background:      "hsl(205deg 85% 95%)",
  },
  {
    id:         "indigo",
    name:       "Indigo",
    color:      "hsl(250, 80%, 50%)",
    background: "hsl(230, 80%, 97%)",
  },
  {
    id:         "dark",
    name:       "Dark",
    color:      "hsl(250, 70%, 30%)",
    background: "hsl(230, 20%, 97%)",
  },
]

export default GUIDE_COLORS
