import updateGuide from 'actions/updateGuide'

const publishGuideDraft = async (guideRef, draft) => {
    
  const values = {
    is_live: true,
    is_coming_soon: draft.is_coming_soon,
    live_version_draft_id: draft.id,
    live_version: draft,
  }

  return updateGuide(guideRef, values)
}

export default publishGuideDraft
