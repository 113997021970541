import React from 'react'
import { NodeViewWrapper } from '@tiptap/react'
import styled from 'styled-components/macro'
import { FirestoreDocument } from 'react-firestore'

import { RESOURCE_LINK_HTML_TAG } from './EditorConstants'

const ResourceLinkComponent = ({
  node,
  editor,
}) => {

  const isEditable = editor.options.editable

  const { url, resourceId } = node.attrs

  return <FirestoreDocument path={`resources/${resourceId}`}>
    {({isLoading, error, data}) => {

      if (isLoading) {
        return 'Loading...'
      }

      if (error) {
        return 'Error getting resource'
      }
      
      console.log(data.metadata)
      
      const title = data?.metadata?.title || 'Unknown page'
      const description = data?.metadata?.description || 'No description available'
      const thumbnailUrl = data?.metadata?.image

      const content = <ResourceLinkContent>
        <ResourceLinkDisplayThumb thumbnailUrl={thumbnailUrl} />
        <ResourceLinkDisplayInfo>
          <ResourceLinkTitle>{title}</ResourceLinkTitle>
          <ResourceLinkDescription>{description}</ResourceLinkDescription>
          <ResourceLinkUrl href={url} target="_blank">{url}</ResourceLinkUrl>
        </ResourceLinkDisplayInfo>
      </ResourceLinkContent>

      return <NodeViewWrapper className={RESOURCE_LINK_HTML_TAG}>
        { isEditable ?
          <ResourceLinkEditorWrapper data-drag-handle>
            {content}
          </ResourceLinkEditorWrapper>
        :
          <ResourceLinkDisplayWrapper href={url} target="_blank">
            {content}
          </ResourceLinkDisplayWrapper>
        }
      </NodeViewWrapper>
    }}
  </FirestoreDocument>

}


// wrappers
const ResourceLinkEditorWrapper = styled.div`
  display: block;
  margin: 0;
  
  background: var(--background-item);
  border-radius: 8px;
  overflow: hidden;

  cursor: move;
`
const ResourceLinkDisplayWrapper = styled.a`
  display: block;
  margin: 0;
  
  border: 1px solid var(--hairline-dark);
  box-shadow: 0 4px 4px var(--shadow);
  border-radius: 8px;
  overflow: hidden;

  cursor: pointer;
  text-decoration: none !important;

  &:hover {
    border-color: var(--hairline-darker);
  }
`

// content

const ResourceLinkContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  
  @media(max-width: 599px) {
    grid-template-columns: auto;
  }
`
const ResourceLinkDisplayInfo = styled.div`
  padding: 1rem;
`
const ResourceLinkDisplayThumb = styled.div`
  background: #eee;
  background-image: ${p => `url(${p.thumbnailUrl})`};
  background-size: cover;
  background-position: center;
  min-width: 200px;
`
const ResourceLinkTitle = styled.div`
  color: var(--text-primary);
  font-size: var(--m);
  font-weight: 500;
  margin: 0 0 .5rem;
`
const ResourceLinkDescription = styled.div`
  color: var(--text-secondary);
  font-size: var(--s);
  margin: 0 0 .5rem;
`
const ResourceLinkUrl = styled.a`
  color: var(--text-tertiary) !important;
  font-size: var(--xs);
  margin: 0;
  cursor: pointer;
  text-decoration: none !important;

  &:hover {
    text-decoration: underline !important;
  }
`

export default ResourceLinkComponent
