import styled from 'styled-components/macro'

const LoadingPage = styled.div`
  text-align: center;
  padding: 40vh 0;
`

export {
  LoadingPage,
}
