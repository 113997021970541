import { prepareDocForCreate } from './helpers/firestoreHelpers'

const createGuideDraft = async (guideRef, values) => {
  
  return guideRef
    .collection('drafts')
    .add(prepareDocForCreate(values))
}

export default createGuideDraft
