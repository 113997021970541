import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import ErrorPage from 'views/_components/ErrorPage'
import NormalLayout from 'views/_layouts/NormalLayout'
import showDayFromTimestamp from 'views/_util/showDayFromTimestamp'
import GuideNav from 'views/guide/GuideNav'
import GuideMenu from 'views/guide/GuideMenu'
import GuideSection from 'views/guide/GuideSection'
import BubbleHeadItem from 'views/_components/BubbleHeadItem'
import { Well } from 'styles/misc'
import GUIDE_COLORS from 'data/GuideColors'
import SaveButton from './SaveButton'
import GuideSaves from './GuideSaves'
import GuideAppendix from './GuideAppendix'

const Guide = ({
  guide,
  version,
  isDraft,
  author,
  auth,
}) => {
    
  const [currentSection, setCurrentSection] = useState(0)

  const goToSection = index => {
    setCurrentSection(index)
    const element = document.getElementById(`#section-header-${index}`)

    // let the guide render and the page reflow first
    setTimeout( () => element.scrollIntoView({ behavior: "smooth" }), 100)
  }

  const lastUpdateTime = guide.updatedOn ? `updated ${showDayFromTimestamp(guide.updatedOn)}` :
                         guide.createdOn ? `created ${showDayFromTimestamp(guide.createdOn)}` :
                         ''
  
  if (!isDraft && !guide.is_live) {
    return <NormalLayout>
      <h2>This guide has either been removed, or is not yet published</h2>
      <p>Please check back soon!</p>
    </NormalLayout>
  }

  if (!version) {
    return <ErrorPage error={new Error('no version for this guide')} />
  }

  return <NormalLayout
    hideCTAs
    title={version.title || 'Unnamed guide'}
    navColor={version.color ? GUIDE_COLORS.find(gc => gc.id === version.color)?.color || 'var(--brand-primary)' : 'var(--brand-primary)'}
  >

    <GuideHeader color={version.color}>
      <GuideColumns>
        <GuideLeft>
        </GuideLeft>
        <GuideMiddle>

          <GuideWrapper>
            <GuideTitle>{version.title}</GuideTitle>
            <GuideSubtitle>{version.subtitle}</GuideSubtitle>
            <GuideByline>
              <a href="#author">{author.displayName}</a>
            </GuideByline>
          </GuideWrapper>

        </GuideMiddle>
        <GuideRight>
        </GuideRight>
      </GuideColumns>
    </GuideHeader>

    <GuideColumns>
      <GuideLeft>
        {!version.is_coming_soon &&
          <GuideNav
            version={version}
            goToSection={goToSection}
            currentSection={currentSection}
          />
        }
      </GuideLeft>
      <GuideMiddle>

        <GuideWrapper>
          
          { (version.is_coming_soon && !isDraft) ?
            <ComingSoonPromo>
              <h3>COMING SOON</h3>
              <p>This guide hasn't been released yet. Add to your library to get notified when it comes out.</p>
              <SaveButton guide={guide} />
            </ComingSoonPromo>
            :
            <>
              <GuideActionBar>
                <SaveButtonWrapper>
                  <SaveButton secondary guide={guide} />
                  <GuideSaves guide={guide} />
                </SaveButtonWrapper>
                <GuideMenu guide={guide} />
              </GuideActionBar>

              <GuideMetadata>
                <GuidePrice>Free</GuidePrice>
                {' · '}
                {version.sections ? version.sections.length : 0} sections
                {' · '}
                {version.topic || 'General knowledge'}
                {' · '}
                {lastUpdateTime}
              </GuideMetadata>
              
              {isDraft &&
                <Well>
                  This is a draft of an unpublished guide
                </Well>
              }


              { version.intro &&
                <GuideIntro>
                  {version.intro}
                </GuideIntro>
              }

              { version.ideal_audience &&
                <GuideIdealAudience>
                  <span>Best for</span>
                  {' '}
                  {version.ideal_audience}
                </GuideIdealAudience>
              }

              { version.sections && version.sections.map( (section, index) => {
                return <SectionWrapper key={index}>
                  <SectionHeading
                    id={`#section-header-${index}`}
                    onClick={() => goToSection(index)}
                    isOpen={currentSection === index}
                  >
                    <SectionHeadingEyebrow>Section {index + 1}</SectionHeadingEyebrow>
                    <SectionHeadingMain>{section.title}</SectionHeadingMain>
                  </SectionHeading>
                  {currentSection === index && <GuideSection section={section} auth={auth} /> }
                </SectionWrapper>
              })}

              <SectionWrapper key={'appendix'}>
                <SectionHeading
                  id={`#section-header-appendix`}
                  onClick={() => goToSection('appendix')}
                  isOpen={currentSection === 'appendix'}
                >
                  <SectionHeadingMain small>Appendix</SectionHeadingMain>
                </SectionHeading>
                {currentSection === 'appendix' && <GuideAppendix version={version} /> }
              </SectionWrapper>

            </>

          } {/* end !is_coming_soon */}

          <hr />

          <AuthorBio id="author" to={`/u/${guide.createdBy}`}>
            <div style={{filter: 'grayscale(0%)'}}>
              <BubbleHeadItem size={96} userId={guide.createdBy} />
            </div>
            <div>
              <AuthorBioName>{author.displayName}</AuthorBioName>
              {' '}
              {version.author_bio}
            </div>
          </AuthorBio>

          <br />
          <br />
          <br />
          <br />

        </GuideWrapper>
      </GuideMiddle>
      <GuideRight>

      </GuideRight>
    </GuideColumns>

  </NormalLayout>
}

// Layout

const GuideColumns = styled.div`
  display: grid;
  grid-template-columns: 8rem auto 8rem;
  grid-column-gap: 2rem;
  
  @media(max-width: 1199px) {
    grid-template-columns: 8rem auto 0;
    grid-column-gap: 2rem;
  }
  @media(max-width: 699px) {
    grid-template-columns: auto;
    grid-column-gap: 0;
  }
`
const GuideLeft = styled.div`
  padding-top: 2rem;

  @media(max-width: 699px) {
    display: none;
  }
  `
const GuideMiddle = styled.div`
`
const GuideRight = styled.div`
  @media(max-width: 699px) {
    display: none;
  }
`
const GuideWrapper = styled.div`
  margin: 0 auto;
  max-width: 40rem;
`

// Header

const GuideHeader = styled.div`
  color:      ${p => p.color ? GUIDE_COLORS.find(gc => gc.id === p.color)?.color      || 'var(--brand-primary)' : 'var(--brand-primary)'};
  background: ${p => p.color ? GUIDE_COLORS.find(gc => gc.id === p.color)?.background || 'var(--background-item)' : 'var(--background-item)'};
  box-shadow: ${p => p.color ? `0 -1000px 0 1000px ${GUIDE_COLORS.find(gc => gc.id === p.color)?.background}` || '0 -1000px 0 1000px var(--background-item)' : '0 -1000px 0 1000px var(--background-item)'};
`
const GuideTitle = styled.h1`
  font-family: var(--font-family-display);
  color: inherit;
  letter-spacing: -0.02em;
  line-height: .8;
  font-weight: 400;

  font-size: 4rem;
  margin: 10vh 0 2.75rem;
`
const GuideSubtitle = styled.div`
  font-weight: 600;
  margin: -2.5rem 0 1.5rem;
  font-size: var(--l);
  opacity: .8;
  `
const GuideByline = styled.div`
  font-size: var(--s);
  font-weight: 400;
  margin: 0 0 1.5rem;
  opacity: .8;

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

// Action bar etc

const GuideActionBar = styled.div`
  /* border-top: 1px solid var(--hairline); */
  margin: 2rem 0 1rem;

  display: grid;
  grid-template-columns: auto max-content;
  grid-column-gap: 1rem;
  align-items: center;
`
const SaveButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  grid-column-gap: 1rem;
`
const GuideMetadata = styled.div`
  font-size: var(--s);
  margin: 0 0 2rem;
`
const GuidePrice = styled.span`
  font-weight: 500;
  color: var(--green-60);
`

// intro

const ComingSoonPromo = styled.div`
  background: var(--background-item);
  padding: 1.5rem;
  margin: 2rem 0 4rem;

  h3 {
    margin: 0 0 .5rem;
  }
`
const GuideIntro = styled.div`
  padding: 0 0 2rem;
  line-height: 1.6;
  font-size: var(--l);
  color: var(--text-primary);
  white-space: pre-line;
`
const GuideIdealAudience = styled.div`
  padding: 0 0 2rem;
  line-height: 1.6;
  font-size: var(--s);
  color: var(--text-secondary);
  white-space: pre-line;
  
  span {
    color: var(--text-primary);
    font-weight: 500;
  }
`

// bio

const AuthorBio = styled(NavLink)`
  display: grid;
  grid-template-rows: max-content auto;
  grid-gap: .5rem;

  font-size: var(--m);
  color: var(--text-secondary);
  padding: 0 0 2rem;

  margin: 6rem auto;
  max-width: 30rem;
  text-align: center;

  @media(max-width: 699px) {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
`
const AuthorBioName = styled.span`
  font-weight: 500;
  display: inline;
  color: var(--text-primary);
`

// sections

const SectionWrapper = styled.div`
  border-top: 1px solid var(--hairline);
`
const SectionHeading = styled.div`
  cursor: pointer;
  padding: 1.5rem 0;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: 1rem;
    top: 50%;
    width: 6px;
    height: 6px;
    margin: -3px 0 0;
    border-style: solid;
    border-radius: 2px;
    border-width: 2px;
    border-color: var(--hairline-dark) var(--hairline-dark) transparent transparent;
    transform: ${p => p.isOpen ? 'rotate(135deg)' : 'rotate(45deg)'};
    transition: transform .2s ease;
  }
`
const SectionHeadingEyebrow = styled.div`
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-size: var(--xs);
  color: var(--text-secondary);
  margin: 0 0 .25rem;
`
const SectionHeadingMain = styled.h2`
  font-weight: 900;
  font-size: ${p => p.small ? 'var(--xl)' : '34px'};
  color: var(--text-primary);
  margin: 0;
`

export default Guide
