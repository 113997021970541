import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import {
  Button,
} from 'styles/buttons'

const PublicHero = () => {
  const history = useHistory()
  
  return <div>
    <MegaHeadline>
      Learning should be&nbsp;free
    </MegaHeadline>
    <MegaSub>
      Long-form, educational guides from experts
    </MegaSub>
    <MegaSubSub>
      100% free · Creative Commons license
    </MegaSubSub>
    <MegaSubButtons>
      <Button onClick={ () => history.push('/manifesto')}>Read the manifesto</Button>
      {' '}
      <Button secondary="secondary" onClick={ () => history.push('/new')}>Write a guide</Button>
    </MegaSubButtons>

    <br />
    <br />

  </div>
}

const MegaHeadline = styled.div`
  font-family: var(--font-family-display);
  color: var(--brand-primary);
  text-transform: uppercase;
  letter-spacing: -0.04em;
  line-height: .7;
  font-weight: 400;
  
  font-size: 15vw;
  margin: 2rem 0;
  text-align: center;

  @media(min-width: 1200px) {
    font-size: 180px;
  }
  @media(max-width: 599px) {
    font-size: 100px;
  }
`
const MegaSub = styled.div`
  margin: 0 0 .5rem;

  color: var(--text-primary);
  font-size: var(--xl);
  text-align: center;
  font-weight: 500;  
  `
const MegaSubSub = styled.div`
  margin: 0 0 2rem;
  
  color: var(--text-secondary);
  font-size: var(--s);
  text-align: center;
  font-weight: 400;
`
const MegaSubButtons = styled.div`
  margin: 0 0 4rem;

  text-align: center;
`

export default PublicHero
