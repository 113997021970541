import Firebase from 'firebase/app'

const signUpWithEmailPassword = ({email, password}) => {
  return Firebase.auth()
    .createUserWithEmailAndPassword(email, password)
    .then( ({user}) => {
      console.log(`Logged in with email as ${user.email}`)
      return user
    })
}

export default signUpWithEmailPassword
