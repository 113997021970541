import Firebase from 'firebase/app'

const logInWithGoogle = () => {
  let provider = new Firebase.auth.GoogleAuthProvider()
  
  return Firebase.auth()
    .signInWithPopup(provider)
    .then( ({user}) => {
      console.log(`Logged in with Google as ${user.displayName}`)
      return user
    })
}

export default logInWithGoogle
