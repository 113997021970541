import React from 'react'
import { FirestoreCollection } from 'react-firestore'

import Home from 'views/home/Home'
import { AppStateContext } from 'views/AppState'
import ErrorPage from 'views/_components/ErrorPage'
import { LoadingPage } from 'styles/loading'

const HomeContainer = () => {

  return <AppStateContext.Consumer>
    { ({ auth, authLoading }) => {

      return <FirestoreCollection path={`/guides`}>
        {({ isLoading, error, data }) => {
          if (isLoading) {
            return <LoadingPage>Loading</LoadingPage>
          }
          if (error) {
            return <ErrorPage error={error} />
          }

          let allGuides = data

          let yourGuides = null
          if (auth) {
            yourGuides = allGuides
              .filter(g => g.createdBy === auth.uid)
          }

          const publicGuides = allGuides
            .filter(g => g.is_live)
            .filter(g => !g.is_coming_soon)
            .slice(0, 3)
          
          return <Home
            auth={auth}
            authLoading={authLoading}
            publicGuides={publicGuides}
            yourGuides={yourGuides}
          />

        }}
      </FirestoreCollection>

    }}
  </AppStateContext.Consumer>
}

export default HomeContainer
