import React from 'react'
import styled from 'styled-components/macro'

import GuideListItem from 'views/guides/GuideListItem'
import { Well } from 'styles/misc'

const GuideList = ({
  guides,
}) => {

  return guides.length > 0 ?
    <GuideListWrapper>
      {guides.map(guide => (
        <GuideListItem
          key={guide.id}
          guide={guide}
        />
      ))}
    </GuideListWrapper>
    :
    <Well>Nothing yet--come back soon!</Well>
}

const GuideListWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 3rem;
  margin: 0 0 2rem;

  @media(max-width: 999px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1rem;
  }

  @media(max-width: 649px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
`

export default GuideList
