import Moment from 'moment'

const showDayFromTimestamp = firestoreDate => {
  const jsDate = firestoreDate.toDate()
  
  // https://stackoverflow.com/questions/32420447/moment-calendar-without-the-time/41918452
  return Moment(jsDate).calendar(null,{
    lastDay : '[yesterday]',
    sameDay : '[today]',
    nextDay : '[today]',
    lastWeek : '[last] dddd',
    nextWeek : 'dddd',
    sameElse : 'L'
  })
}

export default showDayFromTimestamp