import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import GUIDE_COLORS from 'data/GuideColors'

const GuideListItem = ({
  loading,
  guide,
}) => {

  if (loading) {
    return <GuideListItemWrapper to={`/g/${guide.id}`}>
      Loading...
    </GuideListItemWrapper>
  }

  return <GuideListItemWrapper to={`/g/${guide.id}`} color={guide && guide.live_version && guide.live_version.color}>
    {guide.is_coming_soon && 
      <ComingSoonBadge>Coming soon</ComingSoonBadge>
    }
    <GuideTitle>
      {!guide || !guide.live_version || !guide.live_version.title ? 'Unnamed guide' : guide.live_version.title}
    </GuideTitle>
    <GuideSubtitle>
      {!guide || !guide.live_version || !guide.live_version.subtitle ? '' : guide.live_version.subtitle}
    </GuideSubtitle>
  </GuideListItemWrapper>
}

const GuideListItemWrapper = styled(Link)`
  padding: 10rem 1.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  min-height: 20rem;
  position: relative;

  color:      ${p => p.color ? GUIDE_COLORS.find(gc => gc.id === p.color)?.color      || 'var(--brand-primary)' : 'var(--brand-primary)'};
  background: ${p => p.color ? GUIDE_COLORS.find(gc => gc.id === p.color)?.background || 'var(--background-item)' : 'var(--background-item)'};
  /* box-shadow: 0 2px 6px var(--shadow); */
  /* border: 1px solid var(--hairline); */
  
  cursor: pointer;
  
  &:hover {
    /* border: 1px solid var(--hairline-dark); */
  }

  @media(max-width: 599px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
`
const ComingSoonBadge = styled.div`
  position: absolute;
  top: 1rem;
  right: -.5rem;

  letter-spacing: 0.03em;
  text-transform: uppercase;
  line-height: .8;
  font-weight: 600;

  font-size: var(--s);
  color: white;
  background: var(--brand-primary);
  transform: rotate(10deg);
  padding: .33rem 1rem;
`
const GuideTitle = styled.div`
  font-family: var(--font-family-display);
  letter-spacing: -0.02em;
  line-height: .8;
  font-weight: 400;

  font-size: 40px;
  margin: 0 0 .5rem;
`
const GuideSubtitle = styled.div`
  margin: 0 0 .5rem;
  opacity: .8;
  font-weight: 500;
  font-size: var(--m);
  line-height: 1.3;
`

export default GuideListItem
