import Firebase from 'firebase/app'

import { prepareDocForUpdate } from './helpers/firestoreHelpers'

const updateUser = (userId, values) => {
  return Firebase.firestore()
    .collection('users')
    .doc(userId)
    .set(prepareDocForUpdate(values), {merge: true})
}

export default updateUser
