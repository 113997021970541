import React from 'react'
import { FirestoreDocument } from 'react-firestore'
import styled from 'styled-components/macro'
import BUBBLEHEAD_COLORS from 'data/BubbleheadColors'

const BubbleHeadItem = ({
  userId,
  size,
}) => {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
  const colorIndex = chars.indexOf(userId.charAt(0))
  const colors = BUBBLEHEAD_COLORS[colorIndex]

  return <BubbleHeadWrapper>
    <FirestoreDocument path={`users/${userId}`}>
      {({isLoading, error, data}) => {
        const user = data
        return <BubbleHeadImage
          style={{backgroundImage: user && `url(${user.photoURL})`}}
          title={user ? user.displayName : 'Unknown user'}
          size={size}
          textColor={colors.textColor}
          hex={colors.hex}
        >
          {
            isLoading ? '...' :
            error ? '?' :
            !!user.photoURL ? '' :
            !user.displayName ? '' :
            user.displayName.split(" ").slice(0, 2).map(n => n[0]).join('')
          }
        </BubbleHeadImage>
      }}
    </FirestoreDocument>
  </BubbleHeadWrapper>
}

const BubbleHeadWrapper = styled.div`
  margin: 0 3px 0 0;
  display: inline-block;
  position: relative;
  user-select: none;
`
const BubbleHeadImage = styled.div`
  width:       ${ p => `${p.size || 24}px`};
  height:      ${ p => `${p.size || 24}px`};
  line-height: ${ p => `${p.size || 24}px`};
  font-size:   ${ p => `${p.size ? p.size * .6 : 10}px`};
  font-weight: 500;
  text-align: center;
  color: ${ p => p.textColor === 'light' ? 'white' : 'var(--text-primary)'};
  background-color: ${ p => p.hex || 'var(--background-item)'};
  background-size: cover;
  border-radius: 50%;
  text-transform: uppercase;
`

export default BubbleHeadItem
