import React from 'react'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
// import Heading from '@tiptap/extension-heading'
import styled from 'styled-components/macro'

import ResourceLinkExtension from 'views/editor/ResourceLinkExtension'
import { ButtonAppLink, ButtonIconWrapper } from 'styles/buttons'

const GuideSection = ({
  section,
  auth,
}) => {

  const content = section?.content_json_string ?
    JSON.parse(section.content_json_string) :
    ''

  // https://tiptap.dev/guide/custom-extensions#attributes
  // const HeadingsWithIds = Heading.extend({
  //   addAttributes() {
  //     return {
  //       idString: {
  //         default: null,
  //         renderHTML: attributes => {
  //           return {
  //             id: attributes.idString,
  //           }
  //         },
  //       },
  //     }
  //   },
  // })

  // parsing content for h1's for sidebar
  // but how to link on click?
  // maybe requires each heading to have a unique id...
  // https://tiptap.dev/api/extensions/unique-id#docs-sidebar
  // ... or maybe we just extend the heading extension on render? idk
  const headings = content.content
    .filter( node => node.type === 'heading' )
    .filter( node => node.attrs?.level === 2 )
  console.log('headings', headings)

  
  const editor = useEditor({
    editable: false,
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: true,
      }),
      Image,
      ResourceLinkExtension,
    ],
    content,
  })

  return <SectionWrapper>
    <EditorContent editor={editor} />

    {!auth &&
      <SectionUpsell>
        <h3>Don't lose your place!</h3>
        <p>Get free reminder emails for this guide. Unsubscribe anytime.</p>

        <ButtonAppLink small="small" to="/signup">
          <ButtonIconWrapper>
            <i className="material-icons-round">notifications_active</i>
            <span>Remind me</span>
          </ButtonIconWrapper>
        </ButtonAppLink>
      </SectionUpsell>
    }

  </SectionWrapper>
}

const SectionWrapper = styled.div`
  padding: 0 0 4rem;
`
const SectionUpsell = styled.div`
  margin: 3rem 0 0;
  padding: 2rem;
  background: var(--background-item);

  h3 {
    margin: 0 0 .5rem;
    color: var(--text-primary);
  }
  p {
    font-size: var(--s);
    color: var(--text-primary);
    margin: 0 0 1rem;
  }
`

export default GuideSection
