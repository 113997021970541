const LICENSE_OPTIONS = [
  {
    id: 'CC-BY-NC-ND-4.0',
    name: 'Creative Commons Attribution Non-Commercial No-Derivatives 4.0 International',
    description: 'You are free to share copies of this guide for non-commercial purposes only—provided that you do not modify the guide, that you give full credit to the author, and provide a link to this license.',
    url: 'https://creativecommons.org/licenses/by-nc-nd/4.0',
  },
]

export default LICENSE_OPTIONS
