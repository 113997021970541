import React, { useEffect, useState } from 'react'
import Firebase from 'firebase/app'

import LogError from 'actions/LogError'

export const AppStateContext = React.createContext()

const AppState = ({children}) => {

  const [auth, setAuth] = useState(null)
  const [authLoading, setAuthLoading] = useState(true)
  
  useEffect( () => {

    const handleAuthChange = auth => {
      setAuth(auth)
      setAuthLoading(false)

      console.log(`
        UID: ${auth && auth.uid}
        Name: ${auth && auth.displayName}
        Email: ${auth && auth.email}
      `)  
    }    
    
    const handleAuthChangeError = error => {
      LogError('update auth state in AppState', error, true)
      setAuth(null)
      setAuthLoading(false)
    }

    const unsubscribe = Firebase.auth().onAuthStateChanged(handleAuthChange, handleAuthChangeError)
    
    return () => unsubscribe()
  })

  return <AppStateContext.Provider value={{ auth, authLoading }}>
    {children}
  </AppStateContext.Provider>
}

export default AppState