import React from 'react'
import { FirestoreCollection } from 'react-firestore'

import { AppStateContext  } from 'views/AppState'
import ErrorPage from 'views/_components/ErrorPage'
import Guides from 'views/guides/Guides'

import { LoadingPage } from 'styles/loading'

const GuidesContainer = () => {

  return <AppStateContext.Consumer>
    { ({ auth }) => {

      return <FirestoreCollection path={`guides`}>
        {({ isLoading, error, data }) => {
          if (isLoading) {
            return <LoadingPage>Loading</LoadingPage>
          }
          if (error) {
            return <ErrorPage error={error} />
          }

          const allGuides = data
          const newAndNoteworthy = allGuides.filter( g => g.is_live && !g.is_coming_soon)
          const comingSoon = allGuides.filter( g => g.is_live && g.is_coming_soon)

          return <Guides
            auth={auth}
            newAndNoteworthy={newAndNoteworthy}
            comingSoon={comingSoon}
          />
      
        }}
      </FirestoreCollection> // guides
    
    }}
  </AppStateContext.Consumer> // app state
}

export default GuidesContainer
