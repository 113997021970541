// dropdown component that toggles a dropdown on target click

import React from 'react'
import styled from 'styled-components/macro'

import { DropdownMenuWrapper } from 'styles/dropdown'

// https://codepen.io/graubnla/pen/EgdgZm
class Dropdown extends React.Component {
  state = {
    open: false,
  }

  handleClick = event => {
    if (event && event.stopPropagation) {
      event.stopPropagation()
    }
    if (event && event.preventDefault) {
      event.preventDefault()
    }

    // attach/remove event handler
    if (!this.state.open) {
      document.addEventListener('click', this.handleOutsideClick, false)
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }

    this.setState(prevState => ({
      open: this.props.dontShow ? false : !prevState.open,
    }))
  }
  
  handleOutsideClick = event => {
    // ignore clicks on the component itself
    if (this.node && this.node.contains(event.target)) {
      return
    }
    
    this.handleClick(event)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false)
  }

  render() {
    return <DropdownWrapper inlineBlock={this.props.inlineBlock} ref={node => { this.node = node; }}>
      <div onClick={this.handleClick}>
        {this.props.toggle}
      </div>
      {this.state.open && 
        <DropdownMenuWrapper
          padded={this.props.padded}
          lowered={this.props.lowered}
          top={this.props.top}
          right={this.props.right}
          wide={this.props.wide}
        >
          {this.props.children({ close: this.handleClick })}
        </DropdownMenuWrapper>
      }
    </DropdownWrapper>
  }
}

const DropdownWrapper = styled.div`
  position: relative;
  display: ${p => p.inlineBlock ? 'inline-block' : 'block'};
`

export default Dropdown
