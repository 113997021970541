import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import {
  Button,
} from 'styles/buttons'
import UserWelcomeGuideItem from './UserWelcomeGuideItem'

const UserWelcome = ({
  auth,
  yourGuides,
}) => {
  const history = useHistory()

  return <UserWelcomeWrapper>
    <UserWelcomeTitle>Welcome back, {auth.displayName.split(' ')[0]}</UserWelcomeTitle>
    
    <div>
      {
        yourGuides &&
        yourGuides.map( guide => <UserWelcomeGuideItem key={guide.id} guide={guide} /> )
      }

      <br />

      <Button
        small
        secondary="secondary"
        onClick={() => {
          history.push('/new')
        }}
      >
        New guide
      </Button>
    </div>
  </UserWelcomeWrapper>
}

const UserWelcomeWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 5fr;
  grid-column-gap: 2rem;
  
  margin: 2rem 0 4rem;

  @media(max-width: 799px) {
    grid-template-columns: auto;
    grid-row-gap: 1rem;
  }
`
const UserWelcomeTitle = styled.h1`
  margin: 1rem 0 .5rem;
  font-size: var(--xxl);
  font-weight: 800;
`

export default UserWelcome
