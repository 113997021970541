import React, { useState } from 'react'

import LogError from 'actions/LogError'
import changePassword from 'actions/changePassword'
import { Button, AppLink } from 'styles/buttons'
import { Input, Label } from 'styles/forms'

const ChangePassword = () => {

  const [isChangingPassword, setIsChangingPassword] = useState(false)
  const [password, setPassword] = useState('')

  const onSubmit = event => {
    event.preventDefault()
    changePassword({ password })
      .catch( error => {
        if (error.code === "auth/requires-recent-login") {
          alert(`To keep you safe, we only let you change your password if you've logged in very recently.\n\nPlease log out an log in, then try again.`)
          return
        }
        if (error.code === "auth/weak-password") {
          alert(`Hey, that's a pretty weak password!\n\nPlease try again with a longer one.`)
          return
        }
        LogError('change your password', error)
      })
  }

  if (!isChangingPassword) {
    return <AppLink as="span" to="" onClick={() => setIsChangingPassword(true)}>
      Change your password
    </AppLink>
  } else {
    return <form onSubmit={onSubmit}>
      <Label>Choose a new password</Label>
      <Input
        type="password"
        name="password"
        maxLength={1000}
        placeholder="············"
        required
        autoFocus
        value={password}
        onChange={event => setPassword(event.target.value)}
        />

      <Button type="submit">Change password</Button>
      <Button tertiary="tertiary" onClick={() => setIsChangingPassword(false)}>Cancel</Button>
    </form>
  }
}

export default ChangePassword
