import React from 'react'

import ErrorPage from 'views/_components/ErrorPage'
import LogError from 'actions/LogError'

class ErrorBoundary extends React.Component {

  state = {
    error: undefined,
  }

  // When the error happens, set state
  static getDerivedStateFromError(error) {
    return {
      error,
    }
  }

  // Log the error
  componentDidCatch(error, errorInfo) {
    LogError('catch in error boundary', error, true)
  }

  render() {
    if (this.state.error) {
      return <ErrorPage error={this.state.error} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
