// https://yeun.github.io/open-color/

const BUBBLEHEAD_COLORS = [
  // {
  //   name: "gray-0",
  //   hex: "#f8f9fa",
  //   textColor: "dark",
  // },
  {
    name: "gray-1",
    hex: "#f1f3f5",
    textColor: "dark",
  },
  // {
  //   name: "gray-2",
  //   hex: "#e9ecef",
  //   textColor: "dark",
  // },
  {
    name: "gray-3",
    hex: "#dee2e6",
    textColor: "dark",
  },
  // {
  //   name: "gray-4",
  //   hex: "#ced4da",
  //   textColor: "dark",
  // },
  {
    name: "gray-5",
    hex: "#adb5bd",
    textColor: "light",
  },
  // {
  //   name: "gray-6",
  //   hex: "#868e96",
  //   textColor: "light",
  // },
  {
    name: "gray-7",
    hex: "#495057",
    textColor: "light",
  },
  // {
  //   name: "gray-8",
  //   hex: "#343a40",
  //   textColor: "light",
  // },
  {
    name: "gray-9",
    hex: "#212529",
    textColor: "light",
  },
  // {
  //   name: "red-0",
  //   hex: "#fff5f5",
  //   textColor: "dark",
  // },
  {
    name: "red-1",
    hex: "#ffe3e3",
    textColor: "dark",
  },
  // {
  //   name: "red-2",
  //   hex: "#ffc9c9",
  //   textColor: "dark",
  // },
  {
    name: "red-3",
    hex: "#ffa8a8",
    textColor: "dark",
  },
  // {
  //   name: "red-4",
  //   hex: "#ff8787",
  //   textColor: "dark",
  // },
  {
    name: "red-5",
    hex: "#ff6b6b",
    textColor: "light",
  },
  // {
  //   name: "red-6",
  //   hex: "#fa5252",
  //   textColor: "light",
  // },
  {
    name: "red-7",
    hex: "#f03e3e",
    textColor: "light",
  },
  // {
  //   name: "red-8",
  //   hex: "#e03130",
  //   textColor: "light",
  // },
  {
    name: "red-9",
    hex: "#c92a2a",
    textColor: "light",
  },
  // {
  //   name: "pink-0",
  //   hex: "#fff0f6",
  //   textColor: "dark",
  // },
  {
    name: "pink-1",
    hex: "#ffdeeb",
    textColor: "dark",
  },
  // {
  //   name: "pink-2",
  //   hex: "#fcc2d7",
  //   textColor: "dark",
  // },
  {
    name: "pink-3",
    hex: "#faa2c0",
    textColor: "dark",
  },
  // {
  //   name: "pink-4",
  //   hex: "#f783ac",
  //   textColor: "dark",
  // },
  {
    name: "pink-5",
    hex: "#f06595",
    textColor: "light",
  },
  // {
  //   name: "pink-6",
  //   hex: "#e64980",
  //   textColor: "light",
  // },
  {
    name: "pink-7",
    hex: "#d6336c",
    textColor: "light",
  },
  // {
  //   name: "pink-8",
  //   hex: "#c2255c",
  //   textColor: "light",
  // },
  {
    name: "pink-9",
    hex: "#a61e4d",
    textColor: "light",
  },
  // {
  //   name: "grape-0",
  //   hex: "#f8f0fc",
  //   textColor: "dark",
  // },
  {
    name: "grape-1",
    hex: "#f3d9fa",
    textColor: "dark",
  },
  // {
  //   name: "grape-2",
  //   hex: "#eebefa",
  //   textColor: "dark",
  // },
  {
    name: "grape-3",
    hex: "#e599f7",
    textColor: "dark",
  },
  // {
  //   name: "grape-4",
  //   hex: "#da77f2",
  //   textColor: "dark",
  // },
  {
    name: "grape-5",
    hex: "#cc5de8",
    textColor: "light",
  },
  // {
  //   name: "grape-6",
  //   hex: "#be4bdb",
  //   textColor: "light",
  // },
  {
    name: "grape-7",
    hex: "#ae3ec9",
    textColor: "light",
  },
  // {
  //   name: "grape-8",
  //   hex: "#9c36b5",
  //   textColor: "light",
  // },
  {
    name: "grape-9",
    hex: "#862e9c",
    textColor: "light",
  },
  // {
  //   name: "violet-0",
  //   hex: "#f3f0ff",
  //   textColor: "dark",
  // },
  {
    name: "violet-1",
    hex: "#e5dbff",
    textColor: "dark",
  },
  // {
  //   name: "violet-2",
  //   hex: "#d0bfff",
  //   textColor: "dark",
  // },
  {
    name: "violet-3",
    hex: "#b197fc",
    textColor: "dark",
  },
  // {
  //   name: "violet-4",
  //   hex: "#9775fa",
  //   textColor: "dark",
  // },
  {
    name: "violet-5",
    hex: "#845ef7",
    textColor: "light",
  },
  // {
  //   name: "violet-6",
  //   hex: "#7950f2",
  //   textColor: "light",
  // },
  {
    name: "violet-7",
    hex: "#7048e8",
    textColor: "light",
  },
  // {
  //   name: "violet-8",
  //   hex: "#6741d9",
  //   textColor: "light",
  // },
  {
    name: "violet-9",
    hex: "#5f3dc4",
    textColor: "light",
  },
  // {
  //   name: "indigo-0",
  //   hex: "#edf2ff",
  //   textColor: "dark",
  // },
  {
    name: "indigo-1",
    hex: "#dbe4ff",
    textColor: "dark",
  },
  // {
  //   name: "indigo-2",
  //   hex: "#bac8ff",
  //   textColor: "dark",
  // },
  {
    name: "indigo-3",
    hex: "#91a7ff",
    textColor: "dark",
  },
  // {
  //   name: "indigo-4",
  //   hex: "#748ffc",
  //   textColor: "dark",
  // },
  {
    name: "indigo-5",
    hex: "#5c7cfa",
    textColor: "light",
  },
  // {
  //   name: "indigo-6",
  //   hex: "#4c6ef5",
  //   textColor: "light",
  // },
  {
    name: "indigo-7",
    hex: "#4263eb",
    textColor: "light",
  },
  // {
  //   name: "indigo-8",
  //   hex: "#3b5bdb",
  //   textColor: "light",
  // },
  {
    name: "indigo-9",
    hex: "#364fc7",
    textColor: "light",
  },
  // {
  //   name: "blue-0",
  //   hex: "#e7f5ff",
  //   textColor: "dark",
  // },
  {
    name: "blue-1",
    hex: "#d0ebff",
    textColor: "dark",
  },
  // {
  //   name: "blue-2",
  //   hex: "#a5d8ff",
  //   textColor: "dark",
  // },
  {
    name: "blue-3",
    hex: "#74c0fc",
    textColor: "dark",
  },
  // {
  //   name: "blue-4",
  //   hex: "#4dabf7",
  //   textColor: "dark",
  // },
  {
    name: "blue-5",
    hex: "#339af0",
    textColor: "light",
  },
  // {
  //   name: "blue-6",
  //   hex: "#228be6",
  //   textColor: "light",
  // },
  {
    name: "blue-7",
    hex: "#1c7ed6",
    textColor: "light",
  },
  // {
  //   name: "blue-8",
  //   hex: "#1971c2",
  //   textColor: "light",
  // },
  {
    name: "blue-9",
    hex: "#1864ab",
    textColor: "light",
  },
  // {
  //   name: "cyan-0",
  //   hex: "#e3fafc",
  //   textColor: "dark",
  // },
  {
    name: "cyan-1",
    hex: "#c5f6fa",
    textColor: "dark",
  },
  // {
  //   name: "cyan-2",
  //   hex: "#99e9f2",
  //   textColor: "dark",
  // },
  {
    name: "cyan-3",
    hex: "#66d9e8",
    textColor: "dark",
  },
  // {
  //   name: "cyan-4",
  //   hex: "#3bc9db",
  //   textColor: "dark",
  // },
  {
    name: "cyan-5",
    hex: "#22b8cf",
    textColor: "light",
  },
  // {
  //   name: "cyan-6",
  //   hex: "#15aabf",
  //   textColor: "light",
  // },
  {
    name: "cyan-7",
    hex: "#1098ad",
    textColor: "light",
  },
  // {
  //   name: "cyan-8",
  //   hex: "#0c8599",
  //   textColor: "light",
  // },
  {
    name: "cyan-9",
    hex: "#0b7285",
    textColor: "light",
  },
  // {
  //   name: "teal-0",
  //   hex: "#e6fcf5",
  //   textColor: "dark",
  // },
  {
    name: "teal-1",
    hex: "#c3fae8",
    textColor: "dark",
  },
  // {
  //   name: "teal-2",
  //   hex: "#96f2d7",
  //   textColor: "dark",
  // },
  {
    name: "teal-3",
    hex: "#63e6be",
    textColor: "dark",
  },
  // {
  //   name: "teal-4",
  //   hex: "#38d9a9",
  //   textColor: "dark",
  // },
  {
    name: "teal-5",
    hex: "#20c997",
    textColor: "light",
  },
  // {
  //   name: "teal-6",
  //   hex: "#12b886",
  //   textColor: "light",
  // },
  {
    name: "teal-7",
    hex: "#0ca678",
    textColor: "light",
  },
  // {
  //   name: "teal-8",
  //   hex: "#099268",
  //   textColor: "light",
  // },
  {
    name: "teal-9",
    hex: "#087f5b",
    textColor: "light",
  },
  // {
  //   name: "green-0",
  //   hex: "#ebfbee",
  //   textColor: "dark",
  // },
  {
    name: "green-1",
    hex: "#d3f9d8",
    textColor: "dark",
  },
  // {
  //   name: "green-2",
  //   hex: "#b2f2bb",
  //   textColor: "dark",
  // },
  {
    name: "green-3",
    hex: "#8ce99a",
    textColor: "dark",
  },
  // {
  //   name: "green-4",
  //   hex: "#69db7c",
  //   textColor: "dark",
  // },
  {
    name: "green-5",
    hex: "#51cf66",
    textColor: "light",
  },
  // {
  //   name: "green-6",
  //   hex: "#40c057",
  //   textColor: "light",
  // },
  {
    name: "green-7",
    hex: "#37b24d",
    textColor: "light",
  },
  // {
  //   name: "green-8",
  //   hex: "#2f9e44",
  //   textColor: "light",
  // },
  {
    name: "green-9",
    hex: "#2b8a3e",
    textColor: "light",
  },
  // {
  //   name: "lime-0",
  //   hex: "#f4fce3",
  //   textColor: "dark",
  // },
  {
    name: "lime-1",
    hex: "#e9fac8",
    textColor: "dark",
  },
  // {
  //   name: "lime-2",
  //   hex: "#d8f5a2",
  //   textColor: "dark",
  // },
  {
    name: "lime-3",
    hex: "#c0eb75",
    textColor: "dark",
  },
  // {
  //   name: "lime-4",
  //   hex: "#a9e34b",
  //   textColor: "dark",
  // },
  {
    name: "lime-5",
    hex: "#94d82d",
    textColor: "light",
  },
  // {
  //   name: "lime-6",
  //   hex: "#82c91e",
  //   textColor: "light",
  // },
  {
    name: "lime-7",
    hex: "#74b816",
    textColor: "light",
  },
  // {
  //   name: "lime-8",
  //   hex: "#66a80f",
  //   textColor: "light",
  // },
  {
    name: "lime-9",
    hex: "#5c940d",
    textColor: "light",
  },
  // {
  //   name: "yellow-0",
  //   hex: "#fff9db",
  //   textColor: "dark",
  // },
  {
    name: "yellow-1",
    hex: "#fff3bf",
    textColor: "dark",
  },
  // {
  //   name: "yellow-2",
  //   hex: "#ffec99",
  //   textColor: "dark",
  // },
  {
    name: "yellow-3",
    hex: "#ffe066",
    textColor: "dark",
  },
  // {
  //   name: "yellow-4",
  //   hex: "#ffd43b",
  //   textColor: "dark",
  // },
  {
    name: "yellow-5",
    hex: "#fcc419",
    textColor: "light",
  },
  // {
  //   name: "yellow-6",
  //   hex: "#fab005",
  //   textColor: "light",
  // },
  {
    name: "yellow-7",
    hex: "#f59f00",
    textColor: "light",
  },
  // {
  //   name: "yellow-8",
  //   hex: "#f08c00",
  //   textColor: "light",
  // },
  {
    name: "yellow-9",
    hex: "#e67700",
    textColor: "light",
  },
  // {
  //   name: "orange-0",
  //   hex: "#fff4e6",
  //   textColor: "dark",
  // },
  {
    name: "orange-1",
    hex: "#ffe8cc",
    textColor: "dark",
  },
  // {
  //   name: "orange-2",
  //   hex: "#ffd8a8",
  //   textColor: "dark",
  // },
  {
    name: "orange-3",
    hex: "#ffc078",
    textColor: "dark",
  },
  // {
  //   name: "orange-4",
  //   hex: "#ffa94d",
  //   textColor: "dark",
  // },
  {
    name: "orange-5",
    hex: "#ff922b",
    textColor: "light",
  },
  // {
  //   name: "orange-6",
  //   hex: "#fd7e14",
  //   textColor: "light",
  // },
  {
    name: "orange-7",
    hex: "#f76707",
    textColor: "light",
  },
  // {
  //   name: "orange-8",
  //   hex: "#e8590c",
  //   textColor: "light",
  // },
  {
    name: "orange-9",
    hex: "#d9480f",
    textColor: "light",
  },
]

export default BUBBLEHEAD_COLORS
