import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components/macro'

import {
  CloseX,
} from 'styles/misc'

const Modal = ({
  onClose,
  narrow,
  wide,
  noCloseX,
  softCloseX,
  noPadding,
  children,
  fixedHeight,
  shiftedCloseX,
  darkBg,
}) => {
  return ReactDOM.createPortal(
    <ModalWrapper onClick={event => {
      event.stopPropagation()
      onClose && onClose()
    }} >
      <ModalWindowWrapper
        narrow={narrow}
        wide={wide}
        fixedHeight={fixedHeight}
        darkBg={darkBg}
        noPadding={noPadding}
        onClick={event => {
          event.stopPropagation()
        }} 
      >
        
        {!noCloseX &&
          <CloseX
            onClick={onClose}
            shifted={shiftedCloseX}
            soft={softCloseX}
          >
            <i className="material-icons-round">close</i>
          </CloseX>
        }

        {children}
        
      </ModalWindowWrapper>
    </ModalWrapper>,
    document.getElementById('modal-root'),
  )
}

const ModalWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 10vh 0;
  z-index: 11000;
  overflow-y: auto; /* scroll to see the rest of long modals on small screens */

  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: var(--modal-background);
  backdrop-filter: blur(1px);

  @media(max-width: 599px) {
    padding: 10vh 0 0;
  }
`
const ModalWindowWrapper = styled.div`
  padding: ${p => p.noPadding ? 0 : '2rem'};
  background: ${p => p.darkBg ? 'var(--background-page)' : 'white'};
  border-radius: 3px;
  max-width: 100%;
  width: ${p => p.narrow ? '28rem' : p.wide ? '52rem' : '40rem'};
  min-height: ${p => p.fixedHeight ? '500px' : 'auto'};
  box-shadow: 0 10px 20px var(--shadow-overlay);
  z-index: 100;
  position: relative;

  @media(max-width: 599px) {
    border-radius: 10px 10px 0 0;
  }
`

export default Modal
