import React from 'react'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import styled from 'styled-components/macro'

import { AppStateContext } from 'views/AppState'
import BubbleHeadItem from 'views/_components/BubbleHeadItem'
import {
  ButtonLink,
  ButtonAppLink,
  ButtonIconWrapper,
  AppLink,
} from 'styles/buttons'

const NormalLayout = ({
  title,
  children,
  navColor,
  hideCTAs,
}) => {

  return <AppStateContext.Consumer>
    { ({ auth }) => {

      return <NormalLayoutWrapper>
        <Helmet>
          <title>{title ? `${title} | Superclass` : 'Superclass | Free online guides'}</title>
        </Helmet>

        <AppHeader navColor={navColor}>

          <AppHeaderLeft>
            <Logo to="/">
              <LogoText>Superclass</LogoText>
            </Logo>
            <NavBarLink to="/guides" activeClassName="selected">
              Guides
            </NavBarLink>
            <NavBarLink to="/manifesto" activeClassName="selected">
              Manifesto
            </NavBarLink>
          </AppHeaderLeft>

          {!auth ?
            <NavBarLink to="/login">Log in</NavBarLink>
            :
            <AccountWrapper to={`/u/${auth.uid}`}>
              <BubbleHeadItem userId={auth.uid} />
            </AccountWrapper>
          }

        </AppHeader>
        
        <AppMain>
          {children}

          {!hideCTAs &&
            <CTAWrapper>
              <WriteWrapper>
                <h2>Write a guide</h2>
                <p>Unleash your expertise, and help the world learn.</p>
                <p>Our guide creator is the lowest-effort way to build in-depth, long-form instructional guides on the web.</p>
                <br />
                <ButtonAppLink to="/new">
                  <ButtonIconWrapper>
                    <i className="material-icons-round">edit</i>
                    <span>Start writing</span>
                  </ButtonIconWrapper>
                </ButtonAppLink>
              </WriteWrapper>
              <SponsorWrapper>
                <h2>Sponsor or donate</h2>
                <p>We’re breaking the paywall of paid e-learning content. Free for everyone, period. No ads, no investors, no BS.</p>
                <p>We still need your help to keep the lights on.</p>
                <br />
                <ButtonLink secondary="secondary" href="mailto:sam@directedworks.com">
                  <ButtonIconWrapper>
                    <i className="material-icons-round">payments</i>
                    <span>Make a contribution</span>
                  </ButtonIconWrapper>
                </ButtonLink>
              </SponsorWrapper>
            </CTAWrapper>
          }
        </AppMain>

        <AppFooter>
          <AppFooterLinks>
            <AppFooterLinksLeft>
              <div>
                <FooterLogo to={'/'}>Superclass</FooterLogo>
              </div>
              <div>
                <FooterLink to={'/'}>Home</FooterLink>
                <FooterLink to={'/manifesto'}>Manifesto</FooterLink>
              </div>
            </AppFooterLinksLeft>
            <div>
              <FooterLink to={'/login'}>Log in</FooterLink>
              <FooterLink to={'/signup'}>Sign up</FooterLink>
            </div>
          </AppFooterLinks>
          <AppFooterBasement>
            &copy; 2021 Directed Works LLC
            {' · '}
            <BasementLink to={'/terms'}>Terms</BasementLink>
            {' · '}
            <BasementLink to={'/privacy'}>Privacy</BasementLink>
          </AppFooterBasement>
        </AppFooter>

      </NormalLayoutWrapper>
  
    }}
  </AppStateContext.Consumer>
}

const NormalLayoutWrapper = styled.div`
  display: grid;
  grid-template-rows: max-content auto max-content;
  height: auto !important;
  min-height: 100%;
  
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 5vw;
`

// sidebar
const AppHeader = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  padding: .5rem 0;
  color: ${p => p.navColor || 'var(--text-primary)'};
  user-select: none;
`
const AppHeaderLeft = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  align-items: center;
`
const Logo = styled(NavLink)`
  position: relative;
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: .75rem;
  align-items: center;

  outline: none;
  cursor: pointer;
  padding: 1rem;
  margin: 0 0 0 -1rem;
  
  transition: all .2s ease;
`
const LogoText = styled.div`
  color: inherit;
  font-weight: 600;
  text-decoration: none;
`
const NavBarLink = styled(NavLink)`
  padding: .75rem .5rem;
  font-weight: 400;
  font-size: var(--s);
  color: inherit;

  position: relative;
  top: 1px;
`
const AccountWrapper = styled(AppLink)`
  cursor: pointer;
  user-select: none;
`

// main
const AppMain = styled.div`
  padding: 2rem 0;
  background: white;
  min-height: 100%;

  @media(max-width: 599px) {
    /* padding: 2rem 1rem 4rem; */
    margin-left: 0;
    min-height: auto;
  }
`
const CTAWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  align-items: center;
  margin: 8rem 0 2rem;

  @media(max-width: 599px) {
    grid-template-columns: auto;
    grid-row-gap: 1rem;
  }
`
const WriteWrapper = styled.div`
  padding: 3rem;

  background: var(--background-page);

  h2 {
    font-size: var(--l);
    margin: 0 0 1.5rem;
  }
  p {
    color: var(--text-primary);
  }
  
  @media(max-width: 599px) {
    padding: 2rem;
  }
  `
const SponsorWrapper = styled.div`
  padding: 3rem;
  
  h2 {
    font-size: var(--l);
    margin: 0 0 1.5rem;
  }
  @media(max-width: 599px) {
    padding: 2rem;
  }
`

// footer
const AppFooter = styled.div`
  padding: 2rem 0 0;
  border-top: 1px solid var(--hairline-darker);
  margin: 2rem 0 0;
  color: var(--text-primary);
`
const AppFooterLinks = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  grid-column-gap: 2rem;
  justify-content: space-between;
  padding: 0 0 4rem;
`
const AppFooterLinksLeft = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2rem;
`
const FooterLogo = styled(NavLink)`
  font-weight: 600;
  padding: .75rem .5rem;
  font-size: var(--m);
  color: inherit;
  /* color: var(--brand-primary); */
`
const FooterLink = styled(NavLink)`
  display: block;
  padding: .25rem;
  font-weight: 400;
  font-size: var(--s);
  /* color: inherit; */
  color: var(--text-secondary);

  /* position: relative;
  top: 1px;
 */
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
const AppFooterBasement = styled.div`
  text-align: center;
  color: var(--text-tertiary);
  font-size: var(--xs);
  padding: 2rem 0;
`
const BasementLink = styled(NavLink)`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export default NormalLayout
