// update db users on auth

import Firebase from 'firebase/app'
import React from 'react'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/react'

import LogError from 'actions/LogError'
import updateUser from 'actions/updateUser'

class KeepUserUpdated extends React.Component {

  componentDidMount() {
    this.unsubscribe = Firebase.auth()
      .onAuthStateChanged(this.handleAuth, this.handleError)
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  handleAuth = auth => {

    if (!auth) {
      return
    }
    if (auth.isAnonymous) {
      return
    }

    // https://docs.logrocket.com/reference#identify
    const LogRocketInfo = {}
    if (auth.displayName) {
      LogRocketInfo.name = auth.displayName
    }
    if (auth.email) {
      LogRocketInfo.email = auth.email
    }
    if (auth.uid) {
      LogRocket.identify(auth.uid, LogRocketInfo)
    }

    // https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
    const SentryInfo = {}
    if (auth.uid) {
      SentryInfo.id = auth.uid
    }
    if (auth.displayName) {
      SentryInfo.username = auth.displayName
    }
    if (auth.email) {
      SentryInfo.email = auth.email
    }
    if (auth.uid) {
      Sentry.setUser(SentryInfo)
    }

    const update = {}
    if (auth.displayName) {
      update.displayName = auth.displayName
    }
    if (auth.photoURL) {
      update.photoURL = auth.photoURL
    }

    updateUser(auth.uid, update)
      .catch( error => LogError('update this user', error, true) )
  }

  handleError = error => {
    console.error('Auth error; could not update db user info', error)
  }

  render() {
    return null
  }

}

export default KeepUserUpdated