import React from 'react'

import NormalLayout from 'views/_layouts/NormalLayout'
import GuideList from 'views/guides/GuideList'

const Guides = ({
  // auth,
  newAndNoteworthy,
  comingSoon,
}) => {
  return <NormalLayout title="All guides">
    
    <h1>All guides</h1>

    <h2>New</h2>
    <GuideList guides={newAndNoteworthy} />

    <h2>Upcoming</h2>
    <GuideList guides={comingSoon} />

  </NormalLayout>
}

export default Guides
