import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet'
import { Redirect, useHistory } from 'react-router-dom'

import { AppStateContext } from 'views/AppState'
import { Label, Input } from 'styles/forms'
import { Button } from 'styles/buttons'
import logOut from 'actions/logOut'

const AdminLayout = ({
  title,
  children,
}) => {

  const history = useHistory()

  const [userId, setUserId]         = useState('')
  const [userEmail, setUserEmail]   = useState('')

  return <AppStateContext.Consumer>
    { ({ auth, authLoading }) => {

      if (authLoading) {
        return 'loading...'
      }

      if (!auth || auth.email !== 'sam@directedworks.com') {
        return <Redirect
          to={{
            pathname: "/login",
            search: `?redirectTo=${encodeURIComponent(window.location.href)}`,
            state: { message: "You have to log in as an admin first"}
          }}
        />
      }

      return <AdminLayoutWrapper>
        <Helmet>
          <title>{title || 'Account'} :: ADMIN</title>
        </Helmet>

        <AdminSidebar>
          <AdminLogo href="/admin">Superclass Admin</AdminLogo>
          
          <hr />

          <form onSubmit={() => {
            if (userId) {
              history.push(`/admin/user?userId=${userId}`)
            } else if (userEmail) {
              history.push(`/admin/user?userEmail=${userEmail}`)
            } else {
              alert('Please enter either a user ID or a user email')
            }
          }}>
            <Label>User Email</Label>
            <Input
              name="userEmail"
              maxLength={1000}
              placeholder="bob@acme.com"
              wide="wide"
              value={userEmail}
              onChange={event => setUserEmail(event.target.value)}
              small
            />
            <Label>Or user ID</Label>
            <Input
              name="userId"
              maxLength={1000}
              placeholder="abc123"
              wide="wide"
              value={userId}
              onChange={event => setUserId(event.target.value)}
              small
            />
            <Button
              type="submit"
              small
              wide
              secondary="secondary"
            >
              Get user
            </Button>
          </form>

          <hr />

          <p>Signed in as {auth.email}</p>
          <button onClick={ () => logOut().then( () => history.push('/login')) }>
            Log out
          </button>

        </AdminSidebar>

        <AdminMain>
          {children}
        </AdminMain>

      </AdminLayoutWrapper>
      
    }}
  </AppStateContext.Consumer>
  
}

const AdminLayoutWrapper = styled.div`
  display: grid;
  grid-template-columns: 18rem auto;
  grid-column-gap: 3rem;
  height: auto !important;
  min-height: 100%;
`
const AdminSidebar = styled.div`
  padding: 2rem;
  border-right: 1px solid var(--hairline);

  @media(max-width: 599px) {
    padding: 1rem;
  }
`
const AdminMain = styled.div`
  margin: 0 auto;
  padding: 2rem;
  width: 50rem;
  max-width: 100%;

  @media(max-width: 599px) {
    padding: 1rem;
  }

  h2 {
    border-bottom: 2px solid var(--hairline);
    padding-bottom: .25rem;
  }
`
const AdminLogo = styled.a`
  font-family: monospace;
  color: hsl(120, 90%, 70%);
  background: #333;
  padding: 1px 5px;
  text-align: center;
`

export default AdminLayout
