import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components/macro'

import GuideList from 'views/guides/GuideList'
import NormalLayout from 'views/_layouts/NormalLayout'
import {
  Button,
  ButtonAppLink,
} from 'styles/buttons'
import BubbleHeadItem from 'views/_components/BubbleHeadItem'
import logOut from 'actions/logOut'
import LogError from 'actions/LogError'

const Home = ({
  auth,
  user,
  authoredGuides,
}) => {
  const history = useHistory()

  return <NormalLayout title={user.displayName}>
    <ProfileWrapper>

      <ProfileHeader>
        <BubbleHeadItem userId={user.id} size={128} />
        <div>
          <ProfileHeaderName>{user.displayName}</ProfileHeaderName>
          { auth?.uid === user.id &&
            <ButtonAppLink
              secondary="secondary"
              small="small"
              to="/settings"
            >
              Edit profile
            </ButtonAppLink>
          }
          {' '}
          { auth?.uid === user.id &&
            <Button
              secondary="secondary"
              small="small"
              onClick={() => {
                logOut()
                  .then( () => history.push('/'))
                  .catch( error => LogError('sign out', error) )          
              }}
            >
              Log out
            </Button>
          }
        </div>
      </ProfileHeader>

      {authoredGuides.length > 0 && 
        <>
          <h2>Author of</h2>
          <GuideList guides={authoredGuides} />
        </>
      }

    </ProfileWrapper>
  </NormalLayout>
}

const ProfileWrapper = styled.div`
`
const ProfileHeader = styled.div`
  margin: 2rem auto 6rem;
  max-width: 40rem;
  text-align: center;

  @media(max-width: 599px) {
    margin: 1rem auto 3rem;
  }
`
const ProfileHeaderName = styled.h1`
  margin: .5rem 0 1rem;
`

export default Home
