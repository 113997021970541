import Firebase from 'firebase/app'

import { prepareDocForCreate } from './helpers/firestoreHelpers'

const createResource = async (values) => {
  
  return Firebase.firestore()
    .collection('resources')
    .add(prepareDocForCreate(values))
}

export default createResource
