import React from 'react'
import styled from 'styled-components/macro'

import NormalLayout from 'views/_layouts/NormalLayout'
import ChangeName from 'views/settings/ChangeName'
import ChangePassword from 'views/settings/ChangePassword'
import LogOut from 'views/settings/LogOut'

const Settings = ({
  auth,
}) => {  
  return <NormalLayout hideCTAs title="Settings">

    <SettingsTopHeader data-test-id="settings">
      <h1>
        Settings
      </h1>
    </SettingsTopHeader>

    <SettingsWrapper>
      {/* <SettingsHeader>Profile</SettingsHeader> */}
      <ChangeName auth={auth} />
      <hr />
      <ChangePassword />
      <hr />
      <LogOut />
    </SettingsWrapper>

  </NormalLayout>
}

const SettingsWrapper = styled.div`
  margin: 0 0 4rem;
`
// const SettingsHeader = styled.h2`
//   margin: 0 0 1rem;
//   padding: 0 0 .5rem;
//   border-bottom: 2px solid var(--hairline);
// `
const SettingsTopHeader = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  align-items: center;
  margin: 1rem 0 2rem;

  h1 {
    font-size: var(--xl);
    font-weight: 700;
  }
`

export default Settings