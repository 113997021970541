import React from 'react'
import { useHistory} from 'react-router-dom'

import LogError from 'actions/LogError'
import { AppLink } from 'styles/buttons'
import logOut from 'actions/logOut'

const ChangePassword = () => {

  const history = useHistory()

  return <AppLink as="span" to="" onClick={() => {
    logOut()
      .then( () => history.push('/'))
      .catch( error => LogError('sign out', error) )
  }}>
    Log out
  </AppLink>
}

export default ChangePassword
