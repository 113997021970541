import Firebase from 'firebase/app'
import createGuideDraft from './createGuideDraft'

import { prepareDocForCreate } from './helpers/firestoreHelpers'
import getUniqueId from 'actions/helpers/uniqueId'

const createGuide = async ({values}) => {
  
  const emptyDraft = {
    sections: [
      {
        title: 'Introduction',
        content: '',
      }
    ]
  }

  const id = getUniqueId()
  
  const guideRef = Firebase.firestore()
    .collection('guides')
    .doc(id)
    
  await guideRef.set(prepareDocForCreate(values))
  await createGuideDraft(guideRef, emptyDraft)
  return guideRef
}

export default createGuide
