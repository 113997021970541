import React from 'react'
import { Redirect } from 'react-router-dom'

import { AppStateContext } from 'views/AppState'
import { LoadingPage } from 'styles/loading'

const EnsureLoggedIn = ({ children }) => {

  return <AppStateContext.Consumer>
    { ({ auth, authLoading }) => {

      if (authLoading) {
        return <LoadingPage />
      }

      if (!auth) {
        return <Redirect
          to={{
            pathname: "/login",
            search: `?redirectTo=${encodeURIComponent(window.location.href)}`,
            state: { message: "You have to log in to see this page"}
          }}
        />
      }
      if (auth.isAnonymous) {
        return <Redirect
          to={{
            pathname: "/require_account",
            search: `?redirectTo=${encodeURIComponent(window.location.href)}`,
            state: { message: "You'll have to create an account (or log in) to see this page."}
          }}
        />
      }

      return <>
        {children}
      </>

    }}
  </AppStateContext.Consumer>
}

export default EnsureLoggedIn