import React from 'react'

import { AppStateContext } from 'views/AppState'
import Settings from 'views/settings/Settings'
import EnsureLoggedIn from 'views/EnsureLoggedIn'

const SettingsContainer = () => {

  return <EnsureLoggedIn>
    <AppStateContext.Consumer>
      { ({ auth }) => {

        return <Settings
          auth={auth}
        />

      }}
    </AppStateContext.Consumer>
  </EnsureLoggedIn>
}

export default SettingsContainer
