import { prepareDocForUpdate } from './helpers/firestoreHelpers'

const updateGuide = (guideRef, values) => {
  return guideRef
    .set(prepareDocForUpdate(values), {merge: true})

    // after updating, return the document's value
    // .then(() => {
    //   return guideRef
    //     .get()
    //     .then(guideSnap => ({
    //       id: guideSnap.id,
    //       ...guideSnap.data(),
    //     }))
    // })
}

export default updateGuide
