import React from 'react'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import styled from 'styled-components/macro'

import createResource from 'actions/createResource.js'
import ResourceLinkExtension from './ResourceLinkExtension.js'
import { RESOURCE_LINK_NAME } from './EditorConstants.js'

const Editor = ({
  content,
  onUpdate,
  useImage,
  useResourceLinkExtension,
}) => {

  const extensions = [
    StarterKit,
    Link.configure({
      openOnClick: false,
    }),
  ]
  if (useImage) {
    extensions.push(Image)
  }
  if (useResourceLinkExtension) {
    extensions.push(ResourceLinkExtension)
  }

  const editor = useEditor({
    extensions,
    content,
    onUpdate() {
      const jsonContent = this.getJSON()
      onUpdate({ jsonContent })
    },
  })

  const setLink = () => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('Where should the link go?', previousUrl)

    if (!url) { return }

    if (url === '') {
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .unsetLink()
        .run()

      return
    }

    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url })
      .run()
  }

  return <EditorWrapper>
    { editor &&
      <EditorToolbar>

          <EditorToolbarButton
            onClick={ () => editor.chain().focus().toggleBold().run()}
            active={editor.isActive('bold') }
          >
            <i className="material-icons-round">format_bold</i>
          </EditorToolbarButton>

          <EditorToolbarButton
            onClick={ () => editor.chain().focus().toggleItalic().run()}
            active={editor.isActive('italic') }
          >
            <i className="material-icons-round">format_italic</i>
          </EditorToolbarButton>

          <EditorToolbarButton
            onClick={ () => editor.chain().focus().toggleStrike().run()}
            active={editor.isActive('strike') }
          >
            <i className="material-icons-round">strikethrough_s</i>
          </EditorToolbarButton>

          <EditorToolbarSpacer />

          <EditorToolbarButton
            onClick={ () => editor.chain().focus().toggleHeading({ level: 2 }).run()}
            active={editor.isActive('heading', { level: 2 }) }
          >
            <i className="material-icons-round">title</i>
          </EditorToolbarButton>

          <EditorToolbarButton
            onClick={ () => editor.chain().focus().toggleHeading({ level: 3 }).run()}
            active={editor.isActive('heading', { level: 3 }) }
          >
            <i className="material-icons-round">text_fields</i>
          </EditorToolbarButton>
          
          <EditorToolbarButton
            active={editor.isActive('link') }
            onClick={ () => editor.isActive('link') ? editor.chain().focus().unsetLink().run() : setLink()}
          >
            <i className="material-icons-round">link</i>
          </EditorToolbarButton>

          <EditorToolbarSpacer />

          <EditorToolbarButton
            onClick={ () => editor.chain().focus().toggleBulletList().run()}
            active={editor.isActive('bulletList') }
          >
            <i className="material-icons-round">format_list_bulleted</i>
          </EditorToolbarButton>

          <EditorToolbarButton
            onClick={ () => editor.chain().focus().toggleOrderedList().run()}
            active={editor.isActive('orderedList') }
          >
            <i className="material-icons-round">format_list_numbered</i>
          </EditorToolbarButton>

          <EditorToolbarButton
            onClick={ () => editor.chain().focus().toggleBlockquote().run()}
            active={editor.isActive('blockquote') }
          >
            <i className="material-icons-round">format_quote</i>
          </EditorToolbarButton>

          <EditorToolbarButton
            onClick={ () => editor.chain().focus().setHorizontalRule().run() }
          >
            <i className="material-icons-round">horizontal_rule</i>
          </EditorToolbarButton>

          { useImage &&
            <EditorToolbarButton
              onClick={() => {
                const url = window.prompt('Paste image url')
            
                if (!url) {
                  return
                }

                editor
                  .chain()
                  .focus()
                  .setImage({ src: url })
                  .run()

              }}
            >
              <i className="material-icons-round">image</i>
            </EditorToolbarButton>
          }

          { useResourceLinkExtension &&
            <EditorToolbarButton
              onClick={ async () => {
                const url = window.prompt('Paste resource url')
            
                if (!url) {
                  return
                }

                const resource = await createResource({url})

                editor.commands.insertContent({
                  type: RESOURCE_LINK_NAME,
                  attrs: {
                    url,
                    resourceId: resource.id,
                  },
                })

              }}                
            >
              <i className="material-icons-round">add_box</i>
            </EditorToolbarButton>
          }

          <EditorToolbarButton
            onClick={ () => editor.chain().focus().unsetAllMarks().run() }
          >
            <i className="material-icons-round">format_clear</i>
          </EditorToolbarButton>

          <EditorToolbarSpacer />

          <EditorToolbarButton
            onClick={ () => editor.chain().focus().undo().run() }
          >
            <i className="material-icons-round">undo</i>
          </EditorToolbarButton>

          <EditorToolbarButton
            onClick={ () => editor.chain().focus().redo().run() }
          >
            <i className="material-icons-round">redo</i>
          </EditorToolbarButton>
      </EditorToolbar>
    }

    <EditorContentWrapper>
      <EditorContent editor={editor} />
    </EditorContentWrapper>

  </EditorWrapper>
}


const EditorWrapper = styled.div`
`
const EditorToolbar = styled.div`
  border: 1px solid var(--hairline-dark);
  border-radius: 4px 4px 0 0;
  padding: .4rem .6rem;
  margin: 0 0 -1px;

  position: sticky;
  top: 1rem;
  background: white;
  z-index: 1;

  box-shadow: 0 -2rem 0 white, 0 2px 3px var(--shadow);
`
const EditorContentWrapper = styled.div`
  border: 1px solid var(--hairline-dark);
  border-radius: 0 0 4px 4px;
`
const EditorToolbarButton = styled.div`
  display: inline-grid;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  margin-right: .15rem;
  user-select: none;

  background: ${p => p.active ? 'var(--background-item)' : 'white'};
  color: var(--text-primary);
  border-radius: 3px;
  cursor: pointer;
  
  &:hover {
    background: ${p => p.active ? 'var(--gray-90)' : 'var(--hover)'};
  }

  i {
    font-size: 20px;
  }
`
const EditorToolbarSpacer = styled.div`
  display: inline-block;
  width: 2px;
  height: 15px;
  margin: 3px .5rem;
  background: var(--hairline);
`

export default Editor
