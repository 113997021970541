import React from 'react'

import Editor from 'views/editor/Editor'

const EditorWrapper = ({
  localDraft,
  currentSection,
  updateDraft,
}) => {

  const editorContent = localDraft?.sections &&
                        localDraft?.sections[currentSection]?.content_json_string ?
                        JSON.parse(localDraft.sections[currentSection].content_json_string) :
                        ''

  // figures out how to update the localDraft based on new editor content
  // analogous to the function in BasicInfo
  const onEditorUpdate = ({ jsonContent }) => {
    const jsonStringContent = JSON.stringify(jsonContent)
    const newDraft = { ...localDraft }
    newDraft.sections[currentSection].content_json_string = jsonStringContent
    updateDraft(newDraft)
  }

  return <Editor
    content={editorContent}
    useImage
    useResourceLinkExtension
    onUpdate={onEditorUpdate}
  />
}

export default EditorWrapper
