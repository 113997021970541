import React from 'react'
// import styled from 'styled-components/macro'

import NormalLayout from 'views/_layouts/NormalLayout'
import GuideList from 'views/guides/GuideList'
import PublicHero from './PublicHero'
import UserWelcome from './UserWelcome'
import { ButtonAppLink } from 'styles/buttons'

const Home = ({
  auth,
  authLoading,
  publicGuides,
  yourGuides,
}) => {

  return <NormalLayout title="Superclass home">

    {
      authLoading ?
      ''
      :
      auth ?
      <UserWelcome
        auth={auth}
        yourGuides={yourGuides}
      />
      :
      <PublicHero />
    }

    <h2>Recent guides</h2>

    <GuideList guides={publicGuides} />
    {
      publicGuides.length > 0 &&
      <ButtonAppLink secondary="secondary" to="/guides">
        All guides
      </ButtonAppLink>
    }
    

  </NormalLayout>
}

export default Home
