import React from 'react'

import EnsureLoggedIn from 'views/EnsureLoggedIn'
import GuideNew from './GuideNew'

const GuideNewContainer = () => {

  return <EnsureLoggedIn>
    <GuideNew />
  </EnsureLoggedIn>

}

export default GuideNewContainer
