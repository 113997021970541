import React from 'react'
import styled from 'styled-components/macro'

import GUIDE_COLORS from 'data/GuideColors'
import { Select, SelectWrapper } from 'styles/forms'
import LICENSE_OPTIONS from 'data/LicenseOptions'
import {
  Label,
  Input,
  TextareaAuto,
  CheckboxWrapper,
} from 'styles/forms'

const BasicInfo = ({
  localDraft,
  updateDraft,
}) => {

  // figures out how to update the localDraft based on this content
  // analogous to the function in EditorWrapper
  // TODO - just move this to onChanges?
  const updateBasicInfoField = (key, value) => {
    const newDraft = { ...localDraft }
    newDraft[key] = value    
    updateDraft(newDraft)
  }
  
  return <>
    <Label big style={{marginTop: '0'}}>Title</Label>
    <BasicInfoHelper>
      What is your guide called? Keep it short and simple.
    </BasicInfoHelper>
    <Input
      name="title"
      maxLength={1000}
      placeholder="Programming for absolute beginners"
      wide="wide"
      defaultValue={localDraft.title}
      required
      onChange={event => updateBasicInfoField('title', event.target.value)}
    />

    <Label big>Subtitle</Label>
    <BasicInfoHelper>
      Include the topic(s) you're covering and who it’s for
    </BasicInfoHelper>
    <Input
      name="subtitle"
      maxLength={1000}
      placeholder="The complete guide"
      wide="wide"
      defaultValue={localDraft.subtitle}
      required
      onChange={event => updateBasicInfoField('subtitle', event.target.value)}
    />

    <Label big>Intro</Label>
    <BasicInfoHelper>
      Why should someone read this guide? This is your chance to get people interested in what you have to say.
    </BasicInfoHelper>
    <TextareaAuto
      name="intro"
      maxLength={1000}
      placeholder="Have you ever struggled with XYZ?"
      wide="wide"
      defaultValue={localDraft.intro}
      minRows={3}
      required
      onChange={event => updateBasicInfoField('intro', event.target.value)}
    />

    <Label big>Ideal audience</Label>
    <BasicInfoHelper>
      Is this for total beginners, or people who already have some skill or knowledge?
    </BasicInfoHelper>
    <TextareaAuto
      name="ideal_audience"
      maxLength={1000}
      placeholder="Confident dinner cooks who want to level up their game"
      wide="wide"
      defaultValue={localDraft.ideal_audience}
      minRows={3}
      required
      onChange={event => updateBasicInfoField('ideal_audience', event.target.value)}
    />

    <Label big>Author bio</Label>
    <BasicInfoHelper>
      Why are you qualified to write this guide? Make this specific to your topic. For example, if you are writing a painting guide, focus on your art experience.
    </BasicInfoHelper>
    <TextareaAuto
      name="author_bio"
      maxLength={1000}
      placeholder="is a certified..."
      wide="wide"
      defaultValue={localDraft.author_bio}
      minRows={3}
      required
      onChange={event => updateBasicInfoField('author_bio', event.target.value)}
      />
    
    <Label big>Acknowledgments</Label>
    <BasicInfoHelper>
      If there's anyone you want to thank for helping you with this guide, here's the place to do it! (Optional)
    </BasicInfoHelper>
    <TextareaAuto
      name="acknowledgments"
      maxLength={1000}
      placeholder="I would like to thank..."
      wide="wide"
      defaultValue={localDraft.acknowledgments}
      minRows={3}
      required
      onChange={event => updateBasicInfoField('acknowledgments', event.target.value)}
    />

    <hr />

    <Label>Topic</Label>
    <Input
      name="topic"
      maxLength={1000}
      placeholder="Science"
      wide="wide"
      defaultValue={localDraft.topic}
      required
      onChange={event => updateBasicInfoField('topic', event.target.value)}
    />

    <Label>License</Label>
    <SelectWrapper>
      <Select
        defaultValue={localDraft.license}
        required
        onChange={ event => {
          updateBasicInfoField('license', event.target.value)
        }}
      >
        <option>-- Choose license --</option>
        {
          LICENSE_OPTIONS.map( licenseOption => {
            return <option
              key={licenseOption.id}
              value={licenseOption.id}
            >
              {licenseOption.name}
            </option>          
          })
        }
      </Select>
    </SelectWrapper>

    <Label>Color</Label>
    <SelectWrapper>
      <Select
        defaultValue={localDraft.color}
        onChange={ event => {
          updateBasicInfoField('color', event.target.value)
        }}
      >
        <option>-- Choose color --</option>

        {
          GUIDE_COLORS.map( color => {
            return <option
              key={color.id}
              value={color.id}
            >
              {color.name}
            </option>
          })
        }
      </Select>
    </SelectWrapper>

    <Label>Coming soon?</Label>
    <BasicInfoHelper>
      You can publish your guide as "coming soon" so people can add it to their library before it's released.
      Learners will be able to read the title, introduction, and your author bio, but not the rest of the guide.
    </BasicInfoHelper>

    <CheckboxWrapper>
      <input
        type="checkbox"
        checked={localDraft.is_coming_soon}
        onChange={ event => {
          const comingSoon = !!event.target.checked
          updateBasicInfoField('is_coming_soon', comingSoon)
        }}
      />
      <div>
        Publish as "coming soon"
      </div>
    </CheckboxWrapper>

    <br />
    <br />
    <br />
    <br />
    <br />

  </>
}

const BasicInfoHelper = styled.div`
  font-size: var(--xs);
  color: var(--text-secondary);
  margin: 0 0 .5rem;
`

export default BasicInfo
