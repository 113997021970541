import React from 'react'
import { Button, ButtonIconWrapper } from 'styles/buttons'
import styled from 'styled-components/macro'

import NormalLayout from 'views/_layouts/NormalLayout'

const Manifesto = () => {

  return <NormalLayout title="Superclass manifesto">

    <ManifestoHeroTitle>
      Free education for all
    </ManifestoHeroTitle>

    <ManifestoHero>
      <div>
        <ManifestoHeroSub>
          <p>It’s a familiar mission. We just go about it a little differently.</p>
          <p>First, we're tearing down course paywalls. We bring in-depth, interactive educational content directly to learners: no enrolling required.</p>
          <p>The future of online learning is free, open, crowd-sourced, and built for a new generation of learners. Come join us.</p>
        </ManifestoHeroSub>
        <Button
          onClick={ () => window.print() }
          secondary="secondary"
          small
        >
          <ButtonIconWrapper>
            <i className="material-icons-round">print</i>
            <span>Print or download</span>
          </ButtonIconWrapper>
        </Button>
      </div>
      <div>
        <video
          controls
          style={{
            background: 'var(--gray-40)',
            width: '100%',
          }}
        />
      </div>
    </ManifestoHero>

    {/* <ManifestoH2>What we believe</ManifestoH2> */}

    <ManifestoStatement>
      <ManifestoStatementNumber>1</ManifestoStatementNumber>
      <ManifestoStatementTitle>
        Learning should be free
      </ManifestoStatementTitle>
      <ManifestoStatementContent>
        <p>
          Our society needs an educated population to survive, and <strong>education should be a human right</strong>. We believe every single person should have access to the best learning resources in the world—regardless of what they can afford.
        </p>
        <p>
          That’s why Superclass is free to use, for everyone. We’re not trying to raise venture capital or make a billion dollars. If we get traction, we’ll incorporate as a US non-profit (or something that puts impact as our bottom line). We can find other ways to make money.
        </p>
        <p>
          <em>
            Running this site costs money. <a href="mailto:sam@directedworks.com">Support our cause by becoming a patron</a> or <a href="mailto:sam@directedworks.com">talk to us about a charitable donation</a>.
          </em>
        </p>
      </ManifestoStatementContent>
    </ManifestoStatement>

    <ManifestoStatement>
      <ManifestoStatementNumber>2</ManifestoStatementNumber>
      <ManifestoStatementTitle>
        Teach, don't tell
      </ManifestoStatementTitle>
      <ManifestoStatementContent>
        <p>
          Learning new things is hard. Really hard.
        </p>
        <p>
          There are so many new things to learn. It’s impossible to know where to start, and easy to get sidetracked or lost.
        </p>
        <p>
          “Why isn’t it working? Maybe I should just give up.” 
        </p>
        <p>
        <strong>We’re building an experience for beginners</strong>. That means helping authors targeting a specific audience and skill level, starting at the very beginning, structuring content into units, defining new terms, linking to communities of similar learners, scaffolding new information as you progress, tools for practice and hands-on learning.
        </p>
        <p>
          If <a href="https://en.wikipedia.org/wiki/Wikipedia:What_Wikipedia_is_not#Wikipedia_is_not_a_manual,_guidebook,_textbook,_or_scientific_journal" target="_blank" rel="noreferrer">Wikipedia is reference book</a>, Superclass needs to be a vibrant studio space. A place where content, people, and practice come together.
        </p>
        <p>
          <em>
            Read our guide: <a href="https://superclass.co/g/dKLBfEE" target="_blank" rel="noreferrer">How to teach-instead of just tell people things</a>.
          </em>
        </p>
      </ManifestoStatementContent>
    </ManifestoStatement>

    <ManifestoStatement>
      <ManifestoStatementNumber>3</ManifestoStatementNumber>
      <ManifestoStatementTitle>
        Open content wins
      </ManifestoStatementTitle>
      <ManifestoStatementContent>
        <p>
          Superclass isn’t just free as in “free beer”, it’s free as in freedom. That means all our guides are yours to share, print, remix, and republish however you see fit (with proper attribution, and respect for the license of embeded sites).
        </p>
        <p>
          Why? <strong>Because it’s the right thing to do</strong>.
        </p>
        <p>
          But there are more good reasons to make content open:
        </p>
        <ul>
          <li>
            Open content is more easily shared, which makes it more viral, which means more people see it and it has more impact.
          </li>
          <li>
            Open content is easier for learners to consume in their own time, printed, , without requiring a specific deivce, account, or network connection.
          </li>
          <li>
            Instead of getting stale, open content improves over time because it allows others to remix and build on the shoulders of those who came before them.
          </li>
          <li>
            Open content attracts people with the right motivations--people who aren’t in it for a quick dollar and genuinely want to help the world learn.
          </li>
        </ul>
      </ManifestoStatementContent>
    </ManifestoStatement>

    <ManifestoStatement>
      <ManifestoStatementNumber>4</ManifestoStatementNumber>
      <ManifestoStatementTitle>
        Multi-modal learning
      </ManifestoStatementTitle>
      <ManifestoStatementContent>
        <p>
          What’s a better way to learn: text or video? Books, blogs, or tweets? Primary or secondary sources? Lectures, or real-world experience? At Superclass, we think the answer is: <strong>all of the above</strong>.
        </p>
        <p>
          These days, our minds are used to bouncing around and trying different things. 
        </p>
        <p>
          Everyone learns differently. So the more ways you share an idea, the more opportunities it has to “just click” with learners. In fact, most people do better with lots of different examples working together--that’s called multi-modal learning, and it’s at the heart of what we do.
        </p>
        <p>
          We want to collect the best learning resources, wherever they are. If it helps people learn, we want it here. That’s why we support embedding anything in our guides: images, videos, websites, games, and even whole books and courses. If it has a URL, you can make it part of Superclass.
        </p>
      </ManifestoStatementContent>
    </ManifestoStatement>

    <ManifestoStatement>
      <ManifestoStatementNumber>5</ManifestoStatementNumber>
      <ManifestoStatementTitle>
        By the people, for the people
      </ManifestoStatementTitle>
      <ManifestoStatementContent>
        <p>
          Who would be a better art teacher... Pablo Picasso? Or Jane Doe from Wichita Falls, Kansas?
        </p>
        <p>
          Well, it’s hard to say. Maybe Picasso wouldn’t explain art in a way that makes sense to beginners (after all, <a href="https://en.wikipedia.org/wiki/Curse_of_knowledge" target="_blank" rel="noreferrer">experts are often the worst teachers</a>). Maybe Jane is a historian and teacher with 30-years experience getting kids to love art.
        </p>
        <p>
          Superclass doesn’t believe in gate-keeping contributors. Anyone can create a guide here. We just want people who know something, and want to help other people know it too.
        </p>
        <p>
          Wikipedia and Unsplash proved that a small group of helpful online contributors can be more powerful than any hired group of reference book authors. We want to do the same for teaching.
        </p>
        <p>
          That’s why anyone will be able to create a guide on Shuffleboard soon. You’ll be able to add your own guides, remix others’, and build an
        </p>
        <p>
          <em>
            Want to contribute a guide of your own? <a href="/signup">Sign up</a> to get notified when the beta ends.
          </em>
        </p>
      </ManifestoStatementContent>
    </ManifestoStatement>

    <ManifestoStatement>
      <ManifestoStatementNumber>6</ManifestoStatementNumber>
      <ManifestoStatementTitle>
        It's not about money
      </ManifestoStatementTitle>
      <ManifestoStatementContent>
        <p>
          Sometimes it feels like everyone is selling something. Cash rules everything around us—that’s just how the world works.
        </p>
        <p>
          We’re not here to take a stance on capitalism as a whole. We just think there are some places where market forces do more harm than good. Schools and libraries, for example. Online education is another.
        </p>
        <p>
          That’s why <strong>Superclass is not a platform for marketers</strong>. Beginners are impressionable, so teachers should not be biased to selling products or building an audience. Toxic financial incentives will turn us into another website full of junk marketing and monitization, and learners’ experience will suffer.
        </p>
        <p>
          So Superclass has a rule: “no self promotion”. We don’t let authors spam their readers, and we don’t pay royalties for views. The harder it is to make money here, the easier it is for students to be safe, and the more they’ll learn.
        </p>
        <p>
          Learn how Superclass adds friction for marketers and creators seeking profit.
        </p>
      </ManifestoStatementContent>
    </ManifestoStatement>

    <ManifestoStatement>
      <ManifestoStatementNumber>7</ManifestoStatementNumber>
      <ManifestoStatementTitle>
        Tear down the walls
      </ManifestoStatementTitle>
      <ManifestoStatementContent>
        <p>
          Assignments, grades, deadlines, rankings, applications, enrolling, drop dates, midterms, finals, and debt. Is this a bad fever dream, or the state of modern education?
        </p>
        <p>
          Maybe these things are necessary. Maybe they’re relics of an outdated, authoritative teaching style. We don’t really know. What we DO know is that <strong>learning things is hard enough without the pressure of school</strong>.
        </p>
        <p>
          That’s why Superclass isn’t an online school. It’s a content website, not a management tool. We aggressively *remove* barriers to knowledge—tear down every barrier that gets between hungry minds and learning new things—and that includes all the “stuff” that keeps students awake at night.
        </p>
        <p>
          Instead of trying to "fix education", we ask “what would it look like if learning was easy?” What if you didn't need to jump through hoops? What if you could study on your own time, at your own speed? What if we attracted students with carrots, not sticks?
        </p>
      </ManifestoStatementContent>
    </ManifestoStatement>

    {/* TODO - FAQs, bios, list supporters - see figma */}

  </NormalLayout>
}

// hero

const ManifestoHero = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 3rem;
  align-items: center;
  
  padding: 2rem 0 4rem;
  
  @media(max-width: 899px) {    
    grid-template-columns: auto;
    grid-row-gap: 2rem;
  }
  `
const ManifestoHeroTitle = styled.h1`
  font-family: var(--font-family-display);
  color: var(--brand-primary);
  text-transform: uppercase;
  letter-spacing: -0.03em;
  line-height: .8;
  font-weight: 400;

  text-align: center;
  font-size: 60px;
  margin: 2rem 0 2rem;
`
const ManifestoHeroSub = styled.div`
  *, p {
    font-size: var(--l);
    line-height: 1.6;
    font-weight: 400;
    color: var(--text-primary);
    margin: 0 0 1rem;
  }
`

// statement

// const ManifestoH2 = styled.h2`
//   font-family: var(--font-family-display);
//   color: var(--brand-primary);
//   letter-spacing: -0.02em;
//   line-height: .8;
//   font-weight: 400;
  
//   text-align: center;
//   font-size: 50px;
//   margin: 0 0 4rem;
// `
const ManifestoStatement = styled.div`
  display: grid;
  grid-template-columns: 2rem 5fr 7fr;
  grid-column-gap: 3rem;
  padding: 2rem 0 4rem;
  border-top: 1px solid;

  @media(max-width: 599px) {    
    grid-template-columns: auto;
    grid-row-gap: 1rem;
  }
`
const ManifestoStatementNumber = styled.div`
  font-size: var(--xs);
  color: var(--text-primary);
  margin: .25rem 0 0;
`
const ManifestoStatementTitle = styled.h2`
  font-size: var(--xl);
  font-weight: 700;
  color: var(--text-primary);
`
const ManifestoStatementContent = styled.div`
  *, p {
    font-size: var(--s);
    line-height: 1.6;
    color: var(--text-primary);
    margin: 0 0 1rem;
  }

  a {
    text-decoration: underline;
  }
`

export default Manifesto
