import React from 'react'
import { FirestoreDocument, FirestoreCollection } from 'react-firestore'

import Profile from 'views/profile/Profile'
import { AppStateContext } from 'views/AppState'
import ErrorPage from 'views/_components/ErrorPage'
import { LoadingPage } from 'styles/loading'

const ProfileContainer = ({ match }) => {

  const { userId } = match.params
  
  return <AppStateContext.Consumer>
    { ({ auth }) => {

      return <FirestoreDocument path={`/users/${userId}`}>
        {({ isLoading, error, data }) => {
          if (isLoading) {
            return <LoadingPage>Loading</LoadingPage>
          }
          if (error) {
            return <ErrorPage error={error} />
          }

          let user = data

          return <FirestoreCollection path={`/guides`} filter={['createdBy', '==', userId]}>
            {({ isLoading, error, data }) => {
              if (isLoading) {
                return <LoadingPage>Loading</LoadingPage>
              }
              if (error) {
                return <ErrorPage error={error} />
              }

              const authoredGuides = data.filter(g => g.is_live)

              return <Profile
                auth={auth}
                user={user}
                authoredGuides={authoredGuides}
              />
    
            }}
          </FirestoreCollection>   

        }}
      </FirestoreDocument>

    }}
  </AppStateContext.Consumer>
}

export default ProfileContainer
