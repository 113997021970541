import React from 'react'
import styled from 'styled-components/macro'

import { RESOURCE_LINK_NAME } from 'views/editor/EditorConstants.js'
import GuideAppendixItem from './GuideAppendixItem'
import LICENSE_OPTIONS from 'data/LicenseOptions'

const GuideAppendix = ({
  version,
}) => {
  
  const sectionsContents = version?.sections?.map( section => {
    return  section?.content_json_string ?
            JSON.parse(section.content_json_string) :
            ''
  }) || []
  
  const resourceNodes = sectionsContents.map( contents => {
    if (!contents?.content?.length > 0) {
      return []
    }
    return contents.content.filter(node => node.type === RESOURCE_LINK_NAME)
  }).flat()

  const resources = resourceNodes.map( node => node.attrs)

  const license = LICENSE_OPTIONS.find(lo => lo.id === version.license)

  return <AppendixWrapper>
    <h3>Acknowledgments</h3>
    {version.acknowledgments}
    <br />
    <br />

    <h3>All resources</h3>
    <ul>
      {resources.map(resource => <li><GuideAppendixItem resource={resource} /></li> )}
    </ul>
    <br />
    <br />

    <h3>License</h3>
    <GuideLicense href={license?.url || null} target="_blank">
      <svg style={{ width:'24px', height:'24px' }} viewBox="0 0 24 24">
        <path fill="currentColor" d="M11.89,10.34L10.55,11.04C10.41,10.74 10.24,10.53 10.03,10.41C9.82,10.29 9.62,10.23 9.45,10.23C8.55,10.23 8.11,10.82 8.11,12C8.11,12.54 8.22,12.97 8.45,13.29C8.67,13.61 9,13.77 9.45,13.77C10.03,13.77 10.44,13.5 10.68,12.91L11.91,13.54C11.65,14.03 11.29,14.41 10.82,14.69C10.36,14.97 9.85,15.11 9.29,15.11C8.39,15.11 7.67,14.84 7.12,14.29C6.58,13.74 6.3,13 6.3,12C6.3,11.05 6.58,10.3 7.13,9.74C7.69,9.18 8.39,8.9 9.23,8.9C10.47,8.89 11.36,9.38 11.89,10.34M17.66,10.34L16.34,11.04C16.2,10.74 16,10.53 15.81,10.41C15.6,10.29 15.4,10.23 15.21,10.23C14.32,10.23 13.87,10.82 13.87,12C13.87,12.54 14,12.97 14.21,13.29C14.44,13.61 14.77,13.77 15.21,13.77C15.8,13.77 16.21,13.5 16.45,12.91L17.7,13.54C17.42,14.03 17.05,14.41 16.59,14.69C16.12,14.97 15.62,15.11 15.07,15.11C14.17,15.11 13.44,14.84 12.9,14.29C12.36,13.74 12.09,13 12.09,12C12.09,11.05 12.37,10.3 12.92,9.74C13.47,9.18 14.17,8.9 15,8.9C16.26,8.89 17.14,9.38 17.66,10.34M12,3.5A8.5,8.5 0 0,1 20.5,12A8.5,8.5 0 0,1 12,20.5A8.5,8.5 0 0,1 3.5,12A8.5,8.5 0 0,1 12,3.5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
      </svg>
      <div>
        <LicenseName>
          { license?.name || 'Unknown license'}
        </LicenseName>
        <LicenseDescription>
          { license?.description || 'No license description given. Contact author for licensing questions.' }
        </LicenseDescription>
        {license?.url && <LicenseLink>Full license</LicenseLink> }
      </div>
    </GuideLicense>

  </AppendixWrapper>
}

const AppendixWrapper = styled.div`
`

// license

const GuideLicense = styled.a`
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 1rem;
  margin: 2rem 0 4rem;
  `
const LicenseName = styled.div`
  font-size: var(--s);
  font-weight: 600;
  color: var(--text-secondary);
  margin: 0 0 .25rem;
  `
const LicenseDescription = styled.div`
  font-size: var(--s);
  color: var(--text-secondary);
  margin: 0 0 .25rem;
`
const LicenseLink = styled.div`
  font-size: var(--s);
  color: var(--link);
  &:hover {
    text-decoration: underline;
  }
`

export default GuideAppendix
