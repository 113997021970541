import React from 'react'
import { FirestoreCollection } from 'react-firestore'

import saveGuide from 'actions/saveGuide'
import unsaveGuide from 'actions/unsaveGuide'
import { Button, ButtonIconWrapper } from 'styles/buttons'
import { AppStateContext } from 'views/AppState'

const SaveButton = ({
  guide,
  secondary,
}) => {

  return <AppStateContext.Consumer>
    { ({ auth, authLoading}) => {

      if (authLoading) {
        return <Button
          disabled
          secondary
        >
          <ButtonIconWrapper>
            <i className="material-icons-round">bookmark_border</i>
            <span>Save to library</span>
          </ButtonIconWrapper>
        </Button>
      }

      return <FirestoreCollection
        path={'guideSaves'}
        filter={[
          ['guideId', '==', guide.id],
          ['createdBy', '==', auth?.uid || ''],
        ]}
      >
        { ({error, isLoading, data}) => {

          const userSave = data[0]

          return <Button
            disabled={error || isLoading}
            secondary={secondary}
            onClick={() => {
              if (userSave) {
                unsaveGuide(userSave)
              } else {
                saveGuide(guide)
              }
            }}
          >
            <ButtonIconWrapper>
              <i className="material-icons-round">{userSave ? 'bookmark' : 'bookmark_border'}</i>
              <span>{userSave ? 'Saved to library' : 'Save to library'}</span>
            </ButtonIconWrapper>
          </Button>
        }}
      </FirestoreCollection>

    }}
  </AppStateContext.Consumer>
}

export default SaveButton
