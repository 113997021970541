import React from 'react'
import { useHistory } from 'react-router'

import Dropdown from 'views/_components/Dropdown'
import {
  DropdownMenuAction,
  DropdownToggleWrapper,
} from 'styles/dropdown'
import { AppStateContext } from 'views/AppState'

const GuideMenu = ({
  guide,
}) => {
  const history = useHistory()
  
  return <AppStateContext.Consumer>
    { ({ auth }) => {
      return <Dropdown
        lowered
        toggle={
          <DropdownToggleWrapper>
            <i className="material-icons-round">more_horiz</i>
          </DropdownToggleWrapper>
        }
      >
        { ({close}) => {

          const isAuthor = auth && auth.uid === guide.createdBy
          
          return <div>

            {isAuthor &&
              <DropdownMenuAction
                data-test-id="guide-menu-edit"
                onClick={() => {
                  close && close()
                  history.push(`/g/${guide.id}/edit`)
                }}
              >
                <i className="material-icons-round">edit</i>
                Edit
              </DropdownMenuAction>
            }

            {!isAuthor &&
              <DropdownMenuAction
                data-test-id="guide-menu-suggest"
                onClick={() => {
                  close && close()
                  alert('Please contact sam@directedworks.com - thank you for your help!')
                }}
              >
                <i className="material-icons-round">feedback</i>
                Make a suggestion
              </DropdownMenuAction>
            }
            
            {!isAuthor &&
              <DropdownMenuAction
                data-test-id="guide-menu-report"
                onClick={() => {
                  close && close()
                  alert('Please contact sam@directedworks.com - thank you for reporting this!')
                }}
              >
                <i className="material-icons-round">flag</i>
                Report an issue
              </DropdownMenuAction>
            }

            {/* <DropdownMenuAction
              onClick={() => {
                close && close()
                alert('Coming soon!')
              }}
            >
              <i className="material-icons-round">alt_route</i>
              Fork this guide
            </DropdownMenuAction> */}

          </div>
        }}
      </Dropdown>
    }}
  </AppStateContext.Consumer>
}

export default GuideMenu
