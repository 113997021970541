import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'

import KeepUserUpdated from 'views/_util/KeepUserUpdated'
import PageChangeListener from 'views/_util/PageChangeListener'

import ErrorBoundary from 'views/ErrorBoundary'
import ErrorPage from 'views/_components/ErrorPage'
import HomeContainer from 'views/home/HomeContainer'
import Manifesto from 'views/manifesto'
import GuideContainer from 'views/guide/GuideContainer'
import GuidesContainer from 'views/guides/GuidesContainer'
import GuideEditContainer from 'views/guide_edit/GuideEditContainer'
import GuideNewContainer from 'views/guide_edit/GuideNewContainer'
import LogIn from 'views/account/LogIn'
import SignUp from 'views/account/SignUp'
import ResetPassword from 'views/account/ResetPassword'
import User from 'views/admin/User'
import SettingsContainer from 'views/settings/SettingsContainer'
import ProfileContainer from 'views/profile/ProfileContainer'
import ScrollToTop from 'views/_components/ScrollToTop'

// https://stackoverflow.com/questions/49130876/how-to-integrate-error-boundary-in-components-routed-using-react-router
const RouteEB = props => (
  <ErrorBoundary key={props.location?.pathname}>
    <Route {...props} />
  </ErrorBoundary>
)

const AppRouter = () => {
  return <Router>
  
    <ScrollToTop />
  
    <Switch>

      {/* account */}
      <RouteEB path="/login" component={LogIn} />
      <RouteEB path="/signup" component={SignUp} />
      <RouteEB path="/signin" component={SignUp} />
      <RouteEB path="/reset_password" component={ResetPassword} />

      {/* pages */}
      <RouteEB path="/about" component={Manifesto} />
      <RouteEB path="/manifesto" component={Manifesto} />

      {/* guides */}
      <RouteEB path="/new" component={GuideNewContainer} />
      <RouteEB path="/g/:guideString/edit" component={GuideEditContainer} />
      <RouteEB path="/g/:guideString" component={GuideContainer} />
      <RouteEB path="/guides" component={GuidesContainer} />

      {/* users */}
      <RouteEB path="/u/:userId" component={ProfileContainer} />
      <RouteEB path="/settings" component={SettingsContainer} />

      {/* admin */}
      <RouteEB path="/admin/user" component={User} />
      <RouteEB path="/admin" component={User} />

      {/* root */}
      <RouteEB path="/" component={HomeContainer} exact />

      {/* fallback */}
      <RouteEB render={() => <ErrorPage error={new Error('Page not found')} /> } />

    </Switch>

    {/* bonus stuff */}
    <KeepUserUpdated />
    <RouteEB path="/" component={PageChangeListener}/>

  </Router>
}

export default AppRouter
