import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import createGuide from 'actions/createGuide'
import LogError from 'actions/LogError'
import { LoadingPage } from 'styles/loading'

const GuideNew = () => {

  const [guideId, setGuideId] = useState(undefined)

  useEffect( () => {
    createGuide({})
      .then( guideRef => setGuideId(guideRef.id) )
      .catch( error => LogError('create a new guide', error) )
  }, [])

  if (!guideId) {
    return <LoadingPage />
  }

  return <Redirect to={{ pathname: `/g/${guideId}/edit` }} />
}

export default GuideNew
