import Firebase from 'firebase/app'

const logInWithEmailPassword = ({email, password}) => {  
  return Firebase.auth()
    .signInWithEmailAndPassword(email, password)
    .then( ({user}) => {
      console.log(`Logged in with email as ${user.email}`)
      return user
    })
}

export default logInWithEmailPassword
