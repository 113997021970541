import React from 'react'
import { FirestoreDocument } from 'react-firestore'
import styled from 'styled-components/macro'

const GuideAppendixItem = ({
  resource,
}) => {

  return <FirestoreDocument path={`resources/${resource.resourceId}`}>
    {({isLoading, error, data}) => {

      if (isLoading) {
        return 'Loading...'
      }

      if (error) {
        return 'Error getting resource'
      }
            
      const url = data?.metadata?.url
      const title = data?.metadata?.title || 'Unknown page'

      return <ResourceListItem href={url} target="_blank">
        {title}
      </ResourceListItem>

    }}
  </FirestoreDocument>
}

const ResourceListItem = styled.a`
  cursor: pointer;
  color: var(--text-primary);
`

export default GuideAppendixItem
