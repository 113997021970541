import React from 'react'
import styled from 'styled-components/macro'

import updateUser from 'actions/updateUser'
import Modal from 'views/_components/Modal'
import { ButtonLink, Button } from 'styles/buttons'

const GuideEditWelcomeModal = ({
  user,
}) => {

  const closeModal = () => updateUser(user.id, { hasClosedEditorWelcomeModal: true })

  return <Modal onClose={closeModal}>
    <WelcomeModalWrapper>
      <h2>Welcome to your first Superclass guide!</h2>
      <p>You’re about to contribute to a library of accessible, no-cost, open-source learning tools. Good for you!</p>
      <p>We have a few rules for guide authors: the main one is that <strong>no linking to paid personal content</strong> is allowed.</p>
      <p>Thanks for writing. We’re excited to have you :-)</p>
      <br />
      <ButtonLink href="https://superclass.co/g/dKLBfEE">
        Full guidelines
      </ButtonLink>
      {' '}
      <Button secondary="secondary" onClick={closeModal}>
        Start writing
      </Button>
    </WelcomeModalWrapper>
  </Modal>
}

const WelcomeModalWrapper = styled.div`
  padding: 2rem;
  text-align: center;
`

export default GuideEditWelcomeModal
