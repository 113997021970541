import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { FirestoreDocument } from 'react-firestore'

import { AppStateContext  } from 'views/AppState'
import ErrorPage from 'views/_components/ErrorPage'
import Guide from 'views/guide/Guide'

import { LoadingPage } from 'styles/loading'

const GuideContainer = ({ match }) => {

  const { guideString } = match.params
  const guideId = guideString.split('-')[0]

  const location = useLocation()
  const searchParams = queryString.parse(location.search)
  const draftString = searchParams?.preview_draft && decodeURIComponent(searchParams.preview_draft)
  const draftId = draftString?.split('-')[0]

  return <AppStateContext.Consumer>
    { ({ auth }) => {
      return <FirestoreDocument path={`guides/${guideId}`}>
        {({ isLoading, error, data, snapshot }) => {
          if (isLoading) {
            return <LoadingPage>Loading</LoadingPage>
          }
          if (error) {
            return <ErrorPage error={error} />
          }

          const guide = data

          if (!snapshot.exists) {
            return <ErrorPage error={new Error(`This guide does not exist or has been deleted`)} />
          }

          return <FirestoreDocument path={`users/${guide.createdBy}`}>
            {({ isLoading, error, data }) => {
              if (isLoading) {
                return <LoadingPage>Loading</LoadingPage>
              }
              if (error) {
                return <ErrorPage error={error} />
              }
        
              const author = data
        
              // draft preview
              if (draftId) {
                return <FirestoreDocument path={`guides/${guideId}/drafts/${draftId}`}>
                  {({ isLoading, error, data, snapshot }) => {
                    if (isLoading) {
                      return <LoadingPage>Loading</LoadingPage>
                    }
                    if (error) {
                      return <ErrorPage error={error} />
                    }
              
                    const version = data
              
                    if (!snapshot.exists) {
                      return <ErrorPage error={new Error(`This draft does not exist or has been deleted`)} />
                    }
          
                    return <Guide
                      guide={guide}
                      version={version}
                      isDraft={true}
                      author={author}
                      auth={auth}
                    />
                
                  }}
                </FirestoreDocument>
              }
        
              // regular guide
              return <Guide
                guide={guide}
                version={guide.live_version}
                isDraft={false}
                author={author}
              />
        
            }}
          </FirestoreDocument> // author
      
        }}
      </FirestoreDocument> // guide
    
    }}
  </AppStateContext.Consumer> // app state
}

export default GuideContainer
