import React from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import updateGuide from 'actions/updateGuide'
import LogError from 'actions/LogError'

import publishGuideDraft from 'actions/publishGuideDraft'
import deleteGuide from 'actions/deleteGuide'

const PublishMenu = ({
  user,
  guide,
  localDraft,
  waitingForDebounce,
  waitingForServerSave,
}) => {

  const actionWord = guide.is_live ? 'update' : 'publish'

  const history = useHistory()

  return <PublishMenuWrapper>

    { user.can_publish ?
      <PublishAction
        blue
        onClick={ () => {
          const confirm = guide.is_live ? true : window.confirm('Publish your guide? All your changes will be made public.')
          if (!confirm) {
            return
          }

          publishGuideDraft(guide.ref, localDraft)
            .then( () => {
              alert(`Successful ${actionWord}!`)
            })
            .catch( error => {
              LogError(`${actionWord} this guide`, error)
            })
        }}
      >
        { localDraft.is_coming_soon ? 'Publish "coming soon"' : guide.is_live ? 'Publish changes' : 'Publish live' }
      </PublishAction>
    :
      <>
        <PublishAction gray>
          Publish guide
        </PublishAction>
        <BlockMessage>
          During our beta, publishing is limited to approved authors only.
        </BlockMessage>
      </>
    }
    
    <PublishAction
      onClick={ () => {
        const previewURL = `${window.origin}/g/${guide.id}?preview_draft=${localDraft.id}`
        window.open(previewURL, '_blank').focus()
      }}
    >
      { guide.is_live ? 'Preview changes' : 'Preview draft' }
    </PublishAction>

    <hr />
    <br />

    <PublishSubtext>

      <SaveStatus saved={!waitingForDebounce && !waitingForServerSave}>
        { waitingForServerSave ? 'Saving...' : waitingForDebounce ? 'Saving...' : 'Saved'}
      </SaveStatus>

      { guide.is_live &&
        <PublishAnchorLink href={`/g/${guide.id}`} target="_blank">View live</PublishAnchorLink>
      }

      { localDraft && localDraft.sections && localDraft.sections.length < 2 &&
        <PublishLink
          onClick={ () => {
            if (!window.confirm('Are you absolutely sure you wan to delete this guide? This CANNOT be undone.')) {
              return
            }
            if (!window.confirm('ARE YOU REALLY REALLY SURE??? THINK ABOUT IT.')) {
              return
            }
            // TODO - fix momentary "guide does not exist or has been deleted" error
            deleteGuide(guide.ref)
              .then( () => {
                history.push(`/`)
              })
              .catch( error => {
                LogError(`delete this guide`, error)
              })
          }}
        >
          Delete guide
        </PublishLink>
      }
      
      {/* <PublishAppLink to={`/g/${guide.id}/history`}>Version history</PublishAppLink> */}

      <PublishAnchorLink href="https://superclass.co/g/dKLBfEE" target="_blank">Writing help</PublishAnchorLink>
      
      { guide.is_live ?
        <>
          <PublishLink
            onClick={ () => {
              if (!window.confirm('Are you sure you want to unpublish? Nobody will be able to see this guide, but it will not be deleted.')) {
                return
              }
              updateGuide(guide.ref, { is_live: false })
                .catch( error => {
                  LogError('unpublish this guide', error)
                })
            }}
          >
            Unpublish
          </PublishLink>
        </>
        :
        <span>Not published</span>
      }

    </PublishSubtext>

  </PublishMenuWrapper>

}

const PublishMenuWrapper = styled.div`
  position: sticky;
  top: 1rem;
  user-select: none;
`
const PublishAction = styled.div`
  font-size: var(--m);
  font-weight: 500;
  padding: .25rem 0;
  margin: -.25rem 0 .5rem;
  cursor: ${p => !p.gray && 'pointer'};
  color: ${p => p.blue ? 'var(--link)' : p.gray ? 'var(--text-tertiary)' : 'var(--text-primary)'};
`
const BlockMessage = styled.div`
  font-size: var(--xs);
  margin: 0 0 2rem;
  color: var(--text-secondary);
`
const PublishSubtext = styled.div`
  font-size: var(--xs);
  color: var(--text-secondary);
`
const PublishLinkMixin = css`
  color: var(--text-secondary);
  text-decoration: underline;
  cursor: pointer;
  margin: 1em 0;
  display: block;
`
const PublishLink = styled.div`
  ${PublishLinkMixin}
`
const PublishAnchorLink = styled.a`
  ${PublishLinkMixin}
`
// const PublishAppLink = styled(AppLink)`
//   ${PublishLinkMixin}
// `
const SaveStatus = styled.div`
  color: ${p => p.saved ? 'var(--green-50)' : 'var(--text-secondary)'};
  /* font-weight: ${p => p.saved ? '500' : 'inherit'}; */
`

export default PublishMenu
