import React, { useState } from 'react'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'

import LogError from 'actions/LogError'
import logInWithGoogle from 'actions/logInWithGoogle'
import signUpWithEmailPassword from 'actions/signUpWithEmailPassword'
import AccountLayout from 'views/_layouts/AccountLayout'
import OrBar from 'views/_components/OrBar'
import { Button, ButtonSubText, AppLink } from 'styles/buttons'
import { Label, Input } from 'styles/forms'

const SignUp = () => {
  
  const history = useHistory()

  const location = useLocation()
  
  const searchParams = queryString.parse(location.search)
  const defaultEmail = searchParams && searchParams.email ? searchParams.email : ''
  const redirectTo = searchParams?.redirectTo && decodeURIComponent(searchParams.redirectTo)

  const [email, setEmail] = useState(defaultEmail)
  const [password, setPassword] = useState('')

  const onSubmit = event => {
    event.preventDefault()
    signUpWithEmailPassword({ email, password })
      .then( () => {
        if (redirectTo) {
          window.location = redirectTo
        } else {
          history.push('/')
        }
      })
      .catch( error => {
        if (error.code === 'auth/email-already-in-use') {
          alert(`It looks like you've already signed up with that email address. Please try logging in with Google.`)
          return
        }
        if (error.code === 'auth/weak-password') {
          alert(`Hey, that's a pretty weak password!\n\nPlease try again with a longer one.`)
          return
        }

        LogError('sign in', error)
      })
    }

  return <AccountLayout message={location.state?.message} title="Sign up">

    <h2>Create your account</h2>

    <Button
      wide
      block
      destructive="destructive"
      onClick={() => {
        logInWithGoogle()
          .then( () => {
            if (redirectTo) {
              window.location = redirectTo
            } else {
              history.push('/')
            }
          })
          .catch( error => {
            if (error.code === 'auth/popup-closed-by-user') {
              console.log('user closed the auth popup')
              return null
            }
            LogError('sign in', error)
          })
      }}
    >
      Sign up with Google</Button>
    <ButtonSubText>We only use your picture, name, and email</ButtonSubText>

    <OrBar />

    <form onSubmit={onSubmit} data-test-id="signup">
      <Label>Email address</Label>
      <Input
        type="email"
        name="email"
        maxLength={1000}
        placeholder="your@email.com"
        required
        wide="wide"
        value={email}
        onChange={event => setEmail(event.target.value)}
      />
      <Label>Choose a password</Label>
      <Input
        type="password"
        name="password"
        maxLength={1000}
        placeholder="············"
        minlength={8}
        required
        wide="wide"
        value={password}
        onChange={event => setPassword(event.target.value)}
      />
      <Button type="submit" wide block>Sign up with email</Button>
    </form>

    <ButtonSubText>
      By signing up you agree to our
      {' '}
      <AppLink to="/terms">terms of service</AppLink>
    </ButtonSubText>
    
    <hr />

    Already have an account?
    {' '}
    <AppLink
      data-test-id="login-via-signup"
      to={
        redirectTo ?
        `/login?redirectTo=${window.encodeURIComponent(redirectTo)}` :
        `/login`
      }
    >
      Log in
    </AppLink>

  </AccountLayout>
}

export default SignUp
