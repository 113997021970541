import TextareaAutosize from 'react-textarea-autosize'
import styled from 'styled-components/macro'

const Label = styled.div`
  margin: ${p => p.big ? '1rem 0 .25rem 0' : '0 0 .5rem 0'};
  font-family: var(--font-family-sans);
  color: var(--text-primary);
  font-size: ${p => p.big ? 'var(--m)' : 'var(--s)'};
  font-weight: ${p => p.big ? '600' : '500'};
`
const Input = styled.input`
  display: inline-block;
  color: var(--text-primary);
  border: 1px solid var(--gray-70);
  font-family: var(--font-family-sans);
  font-size: var(--m);
  padding: ${p => p.big ? '1rem' : p.small ? '.5rem' : '.66rem'};
  border-radius: 3px;
  cursor: pointer;
  margin: ${p => p.wide ? '0 0 1rem' : '0 1rem 0 0'};
  width: ${p => p.wide && '100%'};

  &:hover {
    background: var(--gray-98);
  }
  
  outline: none;
  &:focus,
  &:active {
    border-color: var(--brand-primary);
  }
`
const SelectWrapper = styled.div`
  display: block;
  margin: 0 0 1rem;
  position: relative;
  
  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 1px;
    top: 1px;
    bottom: 1px;
    width: 4rem;
    background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 1) 40%);
    border-radius: 0 3px 3px 0;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 1rem;
    top: calc(50% - 6px);
    width: 4px;
    height: 4px;
    border-style: solid;
    border-width: 2px;
    border-color: transparent transparent var(--gray-70) var(--gray-70);
    border-radius: 2px;
    transform: rotate(-45deg) translate(-50%, -50%);
  }
`
const Select = styled.select`
  appearance: none;
  display: inline-block;
  color: var(--text-primary);
  border: 1px solid var(--gray-70);
  font-family: var(--font-family-sans);
  font-size: var(--m);
  padding: ${p => p.big ? '1rem' : p.small ? '.5rem' : '.66rem'};
  border-radius: 3px;
  cursor: pointer;
  width: ${p => p.wide && '100%'};
  width: 100%;

  &:hover {
    background: var(--gray-98);
  }
  
  outline: none;
  &:focus,
  &:active {
    border-color: var(--brand-primary);
  }
`

const Textarea = styled.textarea`
  display: inline-block;
  color: var(--text-primary);
  border: 1px solid var(--gray-70);
  font-family: var(--font-family-sans);
  font-size: var(--m);
  padding: .66rem;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 0 1rem;

  &:hover {
    background: var(--gray-98);
  }

  outline: none;
  &:focus,
  &:active {
    border-color: var(--brand-primary);
  }
`
const TextareaAuto = styled(TextareaAutosize)`
  width: 100%;

  display: inline-block;
  color: var(--text-primary);
  border: 1px solid var(--gray-70);
  font-family: var(--font-family-sans);
  line-height: 1.4;
  font-size: var(--m);
  padding: .66rem;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 0 1rem;

  &:hover {
    background: var(--gray-98);
  }

  outline: none;
  &:focus,
  &:active {
    border-color: var(--brand-primary);
  }
`
const InputNote = styled.div`
  margin: -.5rem 0 1rem;
  color: var(--text-secondary);
  font-size: var(--s);
`
const InlineForm = styled.form`
  @media(max-width: 599px) {
    Input {
      width: 100%;
      max-width: initial;
      margin: 0 0 .5rem;
    }
  }
`
const CheckboxWrapper = styled.label`
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: .5rem;
  color: var(--text-primary);
  align-items: center;
  margin: 0 0 .5rem;
`

export {
  Label,
  Input,
  
  Select,
  SelectWrapper,

  Textarea,
  TextareaAuto,
  
  CheckboxWrapper,

  InputNote,
  
  InlineForm,
}