import React from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet'

const AccountLayout = ({
  title,
  message,
  children,
}) => {
  return <AccountLayoutWrapper>
    <Helmet>
      <title>{title || 'Account'} | Superclass</title>
    </Helmet>
    <AccountLayoutBox>
      {message && <AccountMessage>{message}</AccountMessage>}
      {children}
    </AccountLayoutBox>
  </AccountLayoutWrapper>
}

const AccountLayoutWrapper = styled.div`
  padding: 6vh .5rem;
  /* background: var(--background-page); */
  height: 100%;

  @media(max-width: 599px) {
    background: white;
    padding: 1rem .5rem;
  }
`
const AccountLayoutBox = styled.div`
  margin: 0 auto;
  padding: 2rem;
  max-width: 22rem;
  background: white;
  border-radius: 5px;
  /* box-shadow: 0 2px 6px var(--shadow); */

  @media(max-width: 599px) {
    padding: 1rem;
    box-shadow: none;
  }
`
const AccountMessage = styled.div`
  padding: .25rem .75rem;
  border-radius: 3px 3px 0 0;
  display: inline-block;
  margin: 0 -.5rem 1.5rem;

  font-weight: 500;
  font-size: var(--s);
  color: var(--green-50);
`

export default AccountLayout
