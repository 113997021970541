import { customAlphabet } from 'nanoid'

const ID_CHARACTERS = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
const ID_SIZE = 7

const getUniqueId = () => {
  // https://www.npmjs.com/package/nanoid#api
  const nanoid = customAlphabet(ID_CHARACTERS, ID_SIZE)
  return nanoid()
}

export default getUniqueId
